// react
import { useState, useEffect } from "react";

// ionic
import { IonList } from "@ionic/react";

// formik
import { Formik, Form, FormikProps } from "formik";

// buttons
import ButtonDuolingoGoogle from "components/buttons/ButtonDuolingoGoogle";
import ButtonDuolingoGithub from "components/buttons/ButtonDuolingoGithub";
import ButtonDuolingoPurple from "components/buttons/templates/ButtonDuolingoPurple";

// components
import Loading from "components/Loading";
import FormHeader from "components/form/FormHeader";
import FormikInput from "components/formik/FormikInput";
import FormikAutoFill from "components/formik/FormikAutoFill";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";

// interfaces
import { UserSignProps } from "interfaces/user";
import { AuthProps, AuthResourceProps } from "interfaces/auth";

// services
import UserService from "services/UserService";
import FirebaseAuthService from "services/auth/FirebaseAuthService";

// parsers
import authParser from "parsers/authParser";

// utils
import * as yup from "yup";
import color from "styles/color";
import styled from "styled-components";
import authUtils from "utils/authUtils";
import errorUtils from "utils/errorUtils";

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background: ${color.dark.bg};

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;

    .sign_up_buttons {
      display: flex;

      button {
        margin: 0 10px;
      }
    }

    .or_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0 0 0;
      width: 80%;

      .or {
        text-align: center;
        margin: 0 10px;
        color: ${color.grey.light};
      }

      hr {
        flex: 1;
        border: 0;
        border-top: 1px solid ${color.grey.stronger};
      }
    }

    form {
      width: 100%;

      .submit_button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 30px;
      }

      .agreement {
        width: 100%;
        font-size: 0.7em;
        color: ${color.grey.middle};
        text-align: center;
        margin-top: 25px;

        a {
          color: ${color.grey.middle};
          text-decoration: underline;
        }
      }
    }
  }
`;

interface SignUpProps {
  show: boolean;
  onClosed: () => void;
  githubLogin: () => void;
  googleLogin: () => void;
  onSignedUp: (auth: AuthProps, hasJarvis: boolean) => void;
  disabledInputs?: boolean;
}

const SignUp = ({
  show,
  onClosed,
  onSignedUp,
  githubLogin,
  googleLogin,
  disabledInputs,
}: SignUpProps) => {
  const [error, setError] = useState("");
  const [animation, setAnimation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(handleShow, [show]);

  function handleShow() {
    if (!show) {
      setError("");
      return;
    }

    setTimeout(() => setAnimation("animate__bounceInUp"));
  }

  function close() {
    setAnimation("animate__bounceOutLeft");
    setTimeout(onClosed);
  }

  function getSchemaValidation() {
    const email = yup
      .string()
      .email("Please enter a valid email")
      .required("Email is missing");
    const password = yup
      .string()
      .required("Password is missing")
      .min(8, "The password must be at least 8 characters");

    return yup.object({
      email,
      password,
    });
  }

  function getInitialValues(): UserSignProps {
    return {
      email: "",
      password: "",
    };
  }

  async function submit(values: UserSignProps) {
    try {
      setIsLoading(true);
      setError("");

      const user: UserSignProps = {
        email: values.email,
        password: values.password,
      };

      const service = new UserService();
      const firebaseAuth = new FirebaseAuthService();
      const response = await service.signUp(user);
      const auth: AuthResourceProps = response.data;

      authUtils.setAccessToken(auth.accessToken);
      await firebaseAuth.signIn(auth.firebaseToken);

      onSignedUp(authParser.map(auth), !!response.data.user.jarvisName);
    } catch (err: unknown) {
      setIsLoading(false);

      const { message } = errorUtils.handle.auth(err);
      setError(message);
    }
  }

  return (
    <>
      {!!animation && (
        <SignUpWrapper className={`animate__animated ${animation}`}>
          <Loading loading={isLoading} />

          <div className="content">
            <div className="sign_up_buttons">
              <ButtonDuolingoGoogle
                onlyIcon
                width="60px"
                onClick={googleLogin}
              />
              <ButtonDuolingoGithub
                onlyIcon
                width="60px"
                onClick={githubLogin}
              />
            </div>

            <div className="or_wrapper">
              <hr aria-orientation="horizontal" />
              <p className="or">Or</p>
              <hr aria-orientation="horizontal" />
            </div>

            <Formik
              onSubmit={submit}
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={getInitialValues()}
              validationSchema={getSchemaValidation()}
            >
              {(props: FormikProps<UserSignProps>) => {
                return (
                  <Form noValidate>
                    <FormikAutoFill
                      props={props}
                      identifier="userSignUpEmail"
                      field="email"
                    />
                    <FormikAutoFill
                      props={props}
                      identifier="userSignUpPassword"
                      field="password"
                    />

                    <IonList>
                      <FormikInput
                        name="email"
                        type="email"
                        label="Email"
                        inputmode="email"
                        autocorrect="off"
                        placeholder="Email"
                        id="userSignUpEmail"
                        autocomplete="email"
                        autocapitalize="off"
                        disabled={disabledInputs}
                      />

                      <FormikInput
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        id="userSignUpPassword"
                        disabled={disabledInputs}
                      />
                    </IonList>

                    <div className="submit_button_wrapper">
                      <ButtonDuolingoPurple
                        widthFull
                        type="submit"
                        height="40px"
                        onClick={() => {}}
                      >
                        Create profile
                      </ButtonDuolingoPurple>
                    </div>

                    <p className="agreement">
                      By signing up, I agree to Bittle’s{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="/doit/terms-conditions"
                      >
                        Terms
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="/doit/terms-conditions"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <ErrorFeedback err={error} />
          <FormHeader hideBrand close={close} position="bottom" />
        </SignUpWrapper>
      )}
    </>
  );
};

export default SignUp;
