// redux
import { useDispatch } from "react-redux";
// import { tagActions } from "redux/slices/tagSlice";

// interfaces
// import { TagProps } from "interfaces/tag";
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// factories
// import { AlertFactoryType } from "factories/AlertFactory";

// components
import { DropZoneDragWhore } from "components/DropZone";
import SudoHandler from "handlers/challengeButtons/sudo/SudoHandler";
import ChallengeBattle from "components/challenge/battle/ChallengeBattle";

// utils
// import numberUtils from "utils/numberUtils";
// import tagGuesserUtils from "utils/code/tagGuesserUtils";

// interface CofCofProps {
//   content: string;
//   type: AlertFactoryType;
// }

interface ChallengeIntroductionProps extends ChallengeTypeHandlerCommonProps {
  onFailTag(): void;
}

const ChallengeIntroduction = ({
  code,
  paused,
  onFailTag,
  challenge,
  wrapperRef,
  isTagValid,
  onSuccessTag,
  handlerWrapperRef,
  wrapperNotifiersRef,
  handlerNotifiersRef,
}: ChallengeIntroductionProps) => {
  const dispatch = useDispatch();
  const { tags, flowInit, flowDone, specialTags, flowStarted, flowSuccess } =
    challenge;

  // function help() {
  //   if (flowDone) return;
  //   if (flowInit && !flowStarted) return cofCof();

  //   const tag = tagGuesserUtils.get(tags, specialTags, code);
  //   if (!tag) return;

  //   add({ ...tag, fromAvatar: true });
  //   dispatch(tagActions.async.help());
  // }

  // function add(tag: TagProps) {
  //   if (isTagValid(tag)) return onSuccessTag(tag);
  //   fail();
  // }

  // function cofCof() {
  //   const wrapper = wrapperRef.current;
  //   if (!wrapper) return;

  //   const { clientWidth, clientHeight } = wrapper;

  //   alertAnimate({
  //     wrapper: wrapper,
  //     factories: [AlertFactory({ ...getGoodCofCof(), slower: true })],
  //     bounds: {
  //       top: `${numberUtils.randomInterval(100, clientHeight - 200)}px`,
  //       left: `${numberUtils.randomInterval(100, clientWidth - 200)}px`,
  //     },
  //   });
  // }

  // function getGoodCofCof(): CofCofProps {
  //   const random = ["???", "?????", "????????"];

  //   return {
  //     content: random[numberUtils.randomInterval(0, random.length - 1)],
  //     type: AlertFactoryType.Success,
  //   };
  // }

  return (
    <>
      <ChallengeBattle
        code={code}
        paused={paused}
        challenge={challenge}
        wrapperRef={wrapperRef}
        onFailedTag={onFailTag}
        isTagValid={isTagValid}
        onSuccessTag={onSuccessTag}
        handlerWrapperRef={handlerWrapperRef}
        handlerNotifiersRef={handlerNotifiersRef}
        wrapperNotifiersRef={wrapperNotifiersRef}
      />

      <DropZoneDragWhore />

      {flowInit && (
        <>
          <SudoHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
          />
        </>
      )}
    </>
  );
};

export default ChallengeIntroduction;
