// redux
import { appActions } from "redux/slices/appSlice";
import { PayloadAction, createSlice, createAction } from "@reduxjs/toolkit";

// enums
import { ChallengeType } from "enums/challengeEnum";

// interfaces
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { DotProgressRefProps } from "components/SlideDotsProgress";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ChallengeProps, ChallengeUpdateProps } from "interfaces/challenge";

const codeInitialState: ChallengeCodeProps = {
  steps: [],
  verified: [],
  finished: [],
  additional: [],
};

export const initialState: ChallengeProps = {
  id: 0,
  tip: "",
  uuid: "",
  tags: [],
  tips: [],
  name: "",
  index: 0,
  frame: 0,
  input: [],
  userId: 0,
  output: [],
  free: false,
  rate: false,
  speeches: [],
  languageId: 0,
  classRoomId: 0,
  specialTags: [],
  gameOver: false,
  currentCode: "",
  avatarInvader: 0,
  speechProgress: {},
  challengeSectionId: 0,
  code: codeInitialState,
  type: ChallengeType.None,
  programmingLanguageId: 0,
  programmingLanguageType: "",
  programmingLanguageAlias: "",

  // flows
  flowDone: false,
  flowInit: false,
  flowFailed: false,
  flowStarted: false,
  flowSuccess: false,
  flowFinished: false,

  help: 0,
  missed: 0,

  // feedback
  up: false,
  down: false,
  feedback: "",
};

interface UpdateSpeechProgressProps {
  speechProgress: DotProgressRefProps;
}

const init = createAction("@async/challenge/init");
const start = createAction("@async/challenge/start");
const done = createAction("@async/challenge/done");
const failed = createAction("@async/challenge/failed");
const restart = createAction("@async/challenge/restart");
const tryAgain = createAction("@async/challenge/tryAgain");
const openModal = createAction(
  "@async/challenge/openModal",
  (challenge: ChallengeProps, section: ChallengeSectionProps) => ({
    payload: { challenge, section },
  })
);
const updateSpeechProgress = createAction(
  "@async/challenge/updateSpeechProgress",
  (payload: UpdateSpeechProgressProps) => ({ payload })
);

export const challengeSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ChallengeProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<ChallengeUpdateProps>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
    incMissed: (state) => {
      state.missed += 1;
    },
    incHelp: (state) => {
      state.help += 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const challengeActions = {
  async: {
    init,
    done,
    start,
    failed,
    restart,
    tryAgain,
    openModal,
    updateSpeechProgress,
  },
  ...challengeSlice.actions,
};

export default challengeSlice.reducer;
