// interfaces
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ChallengeProps, ChallengeCommonProps } from "interfaces/challenge";

// enums
import { ChallengeType } from "enums/challengeEnum";

// components
import ButtonAccordionQuiz from "handlers/buttonsAccordion/ButtonAccordionQuiz";
import ButtonAccordionIntro from "handlers/buttonsAccordion/ButtonAccordionIntro";
import ButtonAccordionExercise from "handlers/buttonsAccordion/ButtonAccordionExercise";

interface ButtonHandlerProps {
  openChallengeModal: (
    challenge: ChallengeProps,
    section: ChallengeSectionProps
  ) => void;
  openChallengeQuizModal: (
    challenge: ChallengeQuizProps,
    section: ChallengeSectionProps
  ) => void;
  section: ChallengeSectionProps;
  challenge: ChallengeCommonProps;
  challenges: ChallengeCommonProps[];
  disabled?: boolean;
}

const ButtonsAccordionHandler = ({
  section,
  disabled,
  challenge,
  challenges,
  openChallengeModal,
  openChallengeQuizModal,
}: ButtonHandlerProps) => {
  function onClickChallengeModal() {
    openChallengeModal(challenge as ChallengeProps, section);
  }

  function onClickChallengeQuizModal() {
    openChallengeQuizModal(challenge as ChallengeQuizProps, section);
  }

  return (
    <>
      {challenge.type === ChallengeType.Introduction && (
        <ButtonAccordionIntro
          disabled={disabled}
          challenge={challenge}
          onClick={onClickChallengeModal}
        />
      )}

      {challenge.type === ChallengeType.Exercise && (
        <ButtonAccordionExercise
          disabled={disabled}
          challenge={challenge}
          onClick={onClickChallengeModal}
        />
      )}

      {challenge.type === ChallengeType.Quiz && (
        <ButtonAccordionQuiz
          disabled={disabled}
          challenge={challenge}
          challenges={challenges}
          onClick={onClickChallengeQuizModal}
        />
      )}
    </>
  );
};

export default ButtonsAccordionHandler;
