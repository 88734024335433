// components
import ButtonAccordion, {
  ButtonAccordionProps,
  ButtonAccordionLabel,
} from "handlers/buttonsAccordion/ButtonAccordion";

const ButtonAccordionExercise = ({
  onClick,
  disabled,
  challenge,
}: ButtonAccordionProps) => {
  return (
    <ButtonAccordion onClick={onClick} disabled={disabled}>
      <ButtonAccordionLabel
        label={challenge.name}
        customClassName={`nerdfy_orange ${
          challenge.flowDone && "bg_nerdfy_gradient"
        }`}
      />
    </ButtonAccordion>
  );
};

export default ButtonAccordionExercise;
