// components
import ButtonDuolingo, {
  ButtonDuolingoTemplateProps,
} from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";

const ButtonDuolingoPurple = ({
  type,
  height,
  outline,
  onClick,
  children,
  disabled,
  widthFull,
  className,
}: ButtonDuolingoTemplateProps) => {
  return (
    <ButtonDuolingo
      type={type}
      height={height}
      onClick={onClick}
      disabled={disabled}
      className={className}
      widthFull={widthFull}
      color={outline ? color.purple : color.white}
      bg={outline ? color.grey.dark : color.purple}
      boxShadowColor={outline ? color.grey.stronger : "#694c7b"}
    >
      {children}
    </ButtonDuolingo>
  );
};

export default ButtonDuolingoPurple;
