// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const CodeTagSvg = ({
  width = "18",
  height = "18",
  color = c.grey.strong,
}: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 12.8 12.8"
      fillRule="evenodd"
    >
      <g>
        <g fill="#333">
          <path
            d="M3.426 10.157.234 6.962a.802.802 0 0 1 0-1.133l3.192-3.194a.8.8 0 1 1 1.132 1.133L1.932 6.395l2.626 2.629a.802.802 0 0 1-1.132 1.133zM8.242 10.165a.802.802 0 0 1 0-1.132l2.626-2.63-2.626-2.627a.802.802 0 0 1 1.132-1.133l3.192 3.195a.802.802 0 0 1 0 1.133l-3.192 3.194a.8.8 0 0 1-1.132 0z"
            fill={color}
            opacity="1"
            data-original="#333333"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default CodeTagSvg;
