// react
import { useState } from "react";

// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import styled from "styled-components";

const Button = styled(ButtonRippleEffect)<{ bottom: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 60px;
  padding: 0;
  margin: 0;
  z-index: 10;
  position: absolute;
  bottom: ${({ bottom }) => bottom}px;
  right: 5px;
  font-size: 1.2em;

  span {
    opacity: 0.5;
  }
`;

interface ChallengeButtonProps {
  color: string;
  onClick(): void;
  label: JSX.Element;
  bottom?: number;
  disabled?: boolean;
  customClassName?: string;
}

const ChallengeButton = ({
  label,
  color,
  onClick,
  disabled,
  bottom = 150,
  customClassName,
}: ChallengeButtonProps) => {
  const [clicked, setClicked] = useState(false);

  function click() {
    if (clicked || disabled) return;

    onClick();
    setClicked(true);
    setTimeout(() => setClicked(false), 250);
  }

  return (
    <Button
      color={color}
      bottom={bottom}
      onClick={click}
      disabled={disabled}
      className={customClassName}
    >
      {label}
    </Button>
  );
};

export default ChallengeButton;
