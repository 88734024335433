// redux
import { appActions } from "redux/slices/appSlice";
import { PayloadAction, createSlice, createAction } from "@reduxjs/toolkit";
import { initialState as challengeSectionInitialState } from "redux/slices/challengeSectionSlice";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";

// enums
import { ChallengeType } from "enums/challengeEnum";
import { NextChallengeDisabledType } from "enums/nextChallengeEnum";

// interfaces
import { NextChallengeProps } from "interfaces/nextChallenge";

const set = createAction(
  "@async/nextChallenge/set",
  (challenge: ChallengeCommonProps, challenges: ChallengesCommonHashProps) => {
    return {
      payload: {
        challenge,
        challenges,
      },
    };
  }
);

const challengeInitialState: ChallengeCommonProps = {
  id: 0,
  uuid: "",
  name: "",
  index: 0,
  free: false,
  classRoomId: 0,
  languageId: 0,
  gameOver: false,
  avatarInvader: 0,
  challengeSectionId: 0,
  type: ChallengeType.None,

  // flows
  flowDone: false,
  flowInit: false,
  flowFailed: false,
  flowStarted: false,
  flowSuccess: false,
  flowFinished: false,

  help: 0,
  missed: 0,

  // feedback
  up: false,
  down: false,
  feedback: "",
};

const initialState: NextChallengeProps = {
  disabled: true,
  challenge: challengeInitialState,
  section: challengeSectionInitialState,
  disabledType: NextChallengeDisabledType.LastChallenge,
};

export const nextChallengeSlice = createSlice({
  name: "nextChallenge",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<NextChallengeProps>) => {
      return action.payload;
    },
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const nextChallengeActions = {
  async: {
    set,
  },
  ...nextChallengeSlice.actions,
};

export default nextChallengeSlice.reducer;
