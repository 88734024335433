// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { challengeActions } from "redux/slices/challengeSlice";
import { challengeCodeActions } from "redux/slices/challengeCodeSlice";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";
import { challengeSectionActions } from "redux/slices/challengeSectionSlice";

// entities
import PlayerFirebaseEntity from "entities/PlayerFirebaseEntity";
import ChallengeStateEntity from "entities/ChallengeStateEntity";
import ChallengeFirebaseEntity from "entities/ChallengeFirebaseEntity";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";
import { ChallengeFirebaseUpdateProps } from "interfaces/challengeFirebase";

// services
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// utils
import stateUtils, { listenerApiType } from "redux/utils/stateUtils";
import challengeCommonUtils from "redux/utils/challengeCommonUtils";

const challengeMiddleware = createListenerMiddleware();

// challenge/init
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.init,
  effect: async (_action, listenerApi) => {
    const { challenge, auth, player } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();
    const values = stateEntity.getFlowInitValues();
    const valuesFirebase = firebaseEntity.getFlowInitValues(
      userId,
      player,
      challenge
    );

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/start
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.start,
  effect: async (_action, listenerApi) => {
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const stateEntity = new ChallengeStateEntity();
    const firebaseEntity = new ChallengeFirebaseEntity();
    const values = stateEntity.getFlowStartedValues();
    const valuesFirebase = firebaseEntity.getFlowStartedValues();

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/done
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.done,
  effect: async (_action, listenerApi) => {
    const { challenge, challenges, player, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const challengeStateEntity = new ChallengeStateEntity();
    const playerFirebaseEntity = new PlayerFirebaseEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const values = challengeStateEntity.getDoneValues(challenge);
    const playerFirebaseValues = playerFirebaseEntity.getDoneValues(player);
    const valuesFirebase = challengeFirebaseEntity.getDoneValues(challenge);

    setNextChallenge({ ...challenge, ...values }, challenges.hash, listenerApi);
    listenerApi.dispatch(challengeActions.update(values));
    listenerApi.dispatch(challengeCodeActions.clear());

    // firebase
    updatePlayerFirebase(userId, playerFirebaseValues);
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

challengeMiddleware.startListening({
  actionCreator: challengeActions.async.failed,
  effect: async (_action, listenerApi) => {
    const { challenge, challenges, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const userId = auth.user.id;

    const challengeStateEntity = new ChallengeStateEntity();
    const playerFirebaseEntity = new PlayerFirebaseEntity();
    const challengeFirebaseEntity = new ChallengeFirebaseEntity();
    const playerValues = playerFirebaseEntity.getFailedValues();
    const values = challengeStateEntity.getFailedValues(challenge);
    const valuesFirebase = challengeFirebaseEntity.getFailedValues(challenge);

    setNextChallenge({ ...challenge, ...values }, challenges.hash, listenerApi);

    listenerApi.dispatch(challengeActions.update(values));
    listenerApi.dispatch(challengeCodeActions.clear());

    // firebase
    updatePlayerFirebase(userId, playerValues);
    updateChallengeFirebase(userId, id, classRoomId, valuesFirebase);
  },
});

// challenge/updateSpeechProgress
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.updateSpeechProgress,
  effect: async ({ payload }, listenerApi) => {
    const { speechProgress } = payload;
    const { challenge, auth } = stateUtils.get(listenerApi);
    const { id, classRoomId } = challenge;
    const values = { speechProgress };
    const userId = auth.user.id;

    listenerApi.dispatch(challengeActions.update(values));

    // firebase
    updateChallengeFirebase(userId, id, classRoomId, values);
  },
});

// challenge/openModal
challengeMiddleware.startListening({
  actionCreator: challengeActions.async.openModal,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, section } = payload;
    const { challenges } = stateUtils.get(listenerApi);

    listenerApi.dispatch(challengeFlowActions.pause());
    listenerApi.dispatch(challengeActions.set(challenge));
    listenerApi.dispatch(challengeSectionActions.set(section));
    listenerApi.dispatch(challengeCodeActions.set(challenge.code));
    listenerApi.dispatch(
      nextChallengeActions.async.set(challenge, challenges.hash)
    );
  },
});

// private

function setNextChallenge(
  challenge: ChallengeCommonProps,
  hash: ChallengesCommonHashProps,
  listenerApi: listenerApiType
) {
  const challenges = challengeCommonUtils.merge(challenge, hash);
  listenerApi.dispatch(nextChallengeActions.async.set(challenge, challenges));
}

function updatePlayerFirebase(
  userId: number | string,
  player: PlayerFirebaseUpdateProps
) {
  if (!userId) return;

  const playerFirebaseService = new PlayerFirebaseService();
  playerFirebaseService.update(userId, player);
}

function updateChallengeFirebase(
  userId: number | string,
  challengeId: number,
  classRoomId: number,
  data: ChallengeFirebaseUpdateProps
) {
  if (!userId) return;
  if (!challengeId) return;
  if (!classRoomId) return;

  const challengeFirebaseService = new PlayerChallengeFirebaseService();
  challengeFirebaseService.update(challengeId, userId, classRoomId, data);
}

export default challengeMiddleware;
