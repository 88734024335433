// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// entities
import JarvisEntity from "entities/characters/JarvisEntity";

// interfaces
import { NpcHashProps } from "interfaces/npc";

const initialState: NpcHashProps = {
  jarvis: {
    name: JarvisEntity.unknownName,
  },
};

export const npcSlice = createSlice({
  name: "npc",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<NpcHashProps>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      return action.payload;
    },
    updateJarvisName: (state, action: PayloadAction<string>) => {
      state.jarvis.name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const npcActions = npcSlice.actions;
export default npcSlice.reducer;
