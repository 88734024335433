// react
import { useState, useEffect } from "react";

// components
import ButtonAccordion, {
  ButtonAccordionProps,
  ButtonAccordionLabel,
} from "handlers/buttonsAccordion/ButtonAccordion";

// entities
import ChallengeQuizAvailabilityEntity from "entities/ChallengeQuizAvailabilityEntity";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ButtonQuizProps extends ButtonAccordionProps {
  challenges: ChallengeCommonProps[];
}

const ButtonAccordionQuiz = ({
  onClick,
  disabled,
  challenge,
  challenges,
}: ButtonQuizProps) => {
  const [isDisabled, setIsDisabled] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleDisabledButton, [challenge, challenges]);

  function handleDisabledButton() {
    setIsDisabled(!isAvailable());
  }

  function isAvailable(): boolean {
    return new ChallengeQuizAvailabilityEntity({
      quiz: challenge,
      challenges,
    }).is();
  }

  return (
    <ButtonAccordion onClick={onClick} disabled={isDisabled || disabled}>
      <ButtonAccordionLabel
        label={challenge.name}
        customClassName={`nerdfy_green ${
          challenge.flowDone && "bg_nerdfy_gradient"
        }`}
      />
    </ButtonAccordion>
  );
};

export default ButtonAccordionQuiz;
