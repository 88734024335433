// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import ProgressBar from "components/ProgressBar";

// handlers
import ButtonsAccordionHandler from "handlers/buttonsAccordion/ButtonsAccordionHandler";

// interfaces
import {
  ChallengeProps,
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 10px;

    > .content {
      padding: 20px;

      .header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 80px;

        .index_wrapper {
          padding: 10px 10px 10px 0;

          div {
            all: unset;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-weight: bold;
            color: ${color.dark.bg};
            background: ${color.grey.stronger};
          }
        }

        h2 {
          all: unset;
          margin-left: 10px;
          font-size: 1.5em;
          line-height: 1.2em;
          color: ${color.grey.strong};
        }
      }

      ul {
        all: unset;
        width: auto;
        padding: 10px 20px;

        li {
          all: unset;
          float: left;
        }
      }
    }
  }
`;

interface ClassRoomAccordionProps {
  openChallengeModal: (
    challenge: ChallengeProps,
    section: ChallengeSectionProps
  ) => void;
  openChallengeQuizModal(
    challenge: ChallengeQuizProps,
    section: ChallengeSectionProps
  ): void;
  sections: ChallengeSectionProps[];
  challenges: ChallengesCommonHashProps;
}

const ClassRoomAccordion = ({
  sections,
  challenges,
  openChallengeModal,
  openChallengeQuizModal,
}: ClassRoomAccordionProps) => {
  const sectionsBrief = useSelector(s.sectionsBrief());

  return (
    <Wrapper>
      <>
        {challenges && sections.length > 0
          ? sections.map((section: ChallengeSectionProps, i: number) => {
              const challengesFromSection = challenges[section.id] || [];

              return (
                <div className="section" key={section.id}>
                  <ProgressBar
                    color="success"
                    value={sectionsBrief[section.id].progress || 0}
                  />

                  <div className="content">
                    <div className="header">
                      <div className="index_wrapper">
                        <div>{i + 1}</div>
                      </div>

                      <h2>{section.name}</h2>
                    </div>

                    <ul>
                      {challengesFromSection.map(
                        (challenge: ChallengeCommonProps) => {
                          return (
                            <li key={`${challenge.type}_${challenge.id}`}>
                              <ButtonsAccordionHandler
                                section={section}
                                challenge={challenge}
                                disabled={false}
                                challenges={challenges[section.id]}
                                openChallengeModal={openChallengeModal}
                                openChallengeQuizModal={openChallengeQuizModal}
                              />
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              );
            })
          : null}
      </>
    </Wrapper>
  );
};

export default ClassRoomAccordion;
