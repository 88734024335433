// services/core
import RequestCoreService from "services/core/RequestCoreService";

// interfaces
import { ClassRoomDtoResourceProps } from "interfaces/classRoom";

export interface GetClassRoomResponseProps {
  data: ClassRoomDtoResourceProps;
}

interface GetDailyInputProps {
  id: number;
  checkpoint: number;
  languageId: number;
}

class ClassRoomDtoService {
  private _resource = "classroom/dto";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  get(languageId: number, id?: number): Promise<GetClassRoomResponseProps> {
    let resource = `${this._resource}`;

    if (id) resource += `/${id}`;
    if (languageId) resource += `/language/${languageId}`;

    return this._request.get(resource);
  }

  getDaily({
    id,
    checkpoint,
    languageId,
  }: GetDailyInputProps): Promise<GetClassRoomResponseProps> {
    const resource = `${this._resource}/${id}/language/${languageId}/checkpoint/${checkpoint}`;
    return this._request.get(resource);
  }
}

export default ClassRoomDtoService;
