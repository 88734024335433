// interfaces
import {
  ChallengeCodeStepProps,
  ChallengeCodeFinishedProps,
  ChallengeCodeAdditionalProps,
  ChallengeCodeStepResourceProps,
  ChallengeCodeFinishedResourceProps,
  ChallengeCodeAdditionalResourceProps,
} from "interfaces/challengeCode";
import {
  IOCodeProps,
  ChallengeProps,
  IOCodeResourceProps,
  ChallengesHashProps,
  ChallengeResourceProps,
} from "interfaces/challenge";
import {
  ProgrammingLanguageProps,
  ProgrammingLanguageResourceProps,
} from "interfaces/programmingLanguage";
import { TagProps } from "interfaces/tag";
import { TipResourceProps, TipProps } from "interfaces/tip";
import { ChallengeFirebaseProps } from "interfaces/challengeFirebase";

// parsers
import challengeFirebaseParser from "parsers/challengeFirebaseParser";

// utils
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import tagUtils from "utils/tagUtils";
import isString from "lodash/isString";

function mix(
  challenges: ChallengeProps[],
  challengesFirebase: ChallengeFirebaseProps[]
): ChallengeProps[] {
  return challenges.reduce((acc, challenge) => {
    const found = challengesFirebase.find((c) => c.id === challenge.id);

    if (found)
      acc.push({ ...challenge, ...challengeFirebaseParser.map(found) });
    else acc.push(challenge);

    return acc;
  }, [] as ChallengeProps[]);
}

function hash(challenges: ChallengeProps[]): ChallengesHashProps {
  return challenges.reduce(
    (acc: { [key: string]: ChallengeProps[] }, challenge) => {
      if (!acc[challenge.challengeSectionId])
        acc[challenge.challengeSectionId] = [];

      acc[challenge.challengeSectionId].push(challenge);
      return acc;
    },
    {}
  );
}

function list(
  challenges: ChallengeResourceProps[],
  programmingLanguages: ProgrammingLanguageResourceProps[]
): ChallengeProps[] {
  return challenges
    .map((challenge) => {
      return map(challenge, programmingLanguages);
    })
    .sort((a, b) => {
      if (a.index > b.index) return 1;
      if (a.index < b.index) return -1;

      return 0;
    });
}

function map(
  challenge: ChallengeResourceProps,
  programmingLanguages: ProgrammingLanguageResourceProps[]
): ChallengeProps {
  const { id, programmingLanguageId } = challenge;
  const tags = mapTags(challenge);
  const pl = getProgrammingLanguages(
    programmingLanguageId,
    programmingLanguages
  );
  const avatar = challenge.avatar || 0;
  const type = challenge.challengeTypeId;

  return {
    id,
    tags,
    type,
    tip: "",
    frame: 0,
    userId: 0,
    rate: false,
    specialTags: [],
    gameOver: false,
    currentCode: "",
    speechProgress: {},
    name: challenge.name,
    uuid: `${type}_${id}`,
    avatarInvader: avatar,
    programmingLanguageId,
    free: !!challenge.free,
    index: challenge.index,
    tips: mapTips(challenge.tips),
    languageId: challenge.languageId,
    programmingLanguageType: pl.type,
    classRoomId: challenge.classroomId,
    programmingLanguageAlias: pl.alias,
    speeches: challenge.speeches || [],
    challengeSectionId: challenge.challengeSectionId,
    input: formatIOCode(challenge.input, programmingLanguages),
    output: formatIOCode(challenge.output, programmingLanguages),

    // code
    code: {
      verified: [],
      finished: mapFinished(challenge.finished),
      additional: mapAdditional(challenge.additional),
      steps: formatSteps(challenge.steps, challenge.additional),
    },

    // flows
    flowDone: false,
    flowInit: false,
    flowFailed: false,
    flowStarted: false,
    flowSuccess: false,
    flowFinished: false,

    // feedback
    up: false,
    down: false,
    feedback: "",

    help: 0,
    missed: 0,
  };
}

function mapAdditional(
  additional: ChallengeCodeAdditionalResourceProps[]
): ChallengeCodeAdditionalProps[] {
  if (isEmpty(additional)) return [];

  return additional.map((a) => ({
    data: a.d,
    g: a.g,
  }));
}

function getProgrammingLanguages(
  id: number,
  programmingLanguages: ProgrammingLanguageResourceProps[]
): ProgrammingLanguageProps {
  const found = findProgrammingLanguage(programmingLanguages, id);
  if (!found)
    return { id: 0, name: "undefined", type: "undefined", alias: "undefined" };

  return found;
}

function mapTips(tips: TipResourceProps[]): TipProps[] {
  if (!tips) return [];

  return tips.map((tip) => ({
    tip: tip.t,
    id: uuidv4(),
    match: tip.m,
  }));
}

function mapFinished(
  finished: ChallengeCodeFinishedResourceProps[]
): ChallengeCodeFinishedProps[] {
  if (isEmpty(finished)) return [];

  return finished.map((f) => ({
    data: f.d,
  }));
}

function formatSteps(
  steps: ChallengeCodeStepResourceProps[],
  additional: {
    [key: number]: ChallengeCodeAdditionalResourceProps;
  }
): ChallengeCodeStepProps[] {
  if (isEmpty(steps)) return [];

  return steps.map((step: ChallengeCodeStepResourceProps, i: number) => {
    const localAdditional = getLocalAdditional(additional, i);
    const globalAdditional = getGlobalAdditional(additional, steps);

    if (!step || !step.d) return { data: [] };

    return {
      data: mapSteps(step.d),
      additional: localAdditional || globalAdditional || { data: "" },
    };
  });
}

function getLocalAdditional(
  additional: {
    [key: number]: ChallengeCodeAdditionalResourceProps;
  },
  i: number
): ChallengeCodeAdditionalProps | undefined {
  const _additional = additional && additional[i];

  if (!_additional) return undefined;

  return {
    data: _additional.d,
    g: _additional.g,
  };
}

function getGlobalAdditional(
  additional: {
    [key: number]: ChallengeCodeAdditionalResourceProps;
  },
  steps: ChallengeCodeStepResourceProps[]
): ChallengeCodeAdditionalProps | undefined {
  if (isEmpty(additional)) return undefined;

  const index = steps.findIndex((_step, i) => additional[i] && additional[i].g);
  if (index === -1) return undefined;

  return {
    data: additional[index].d,
    g: additional[index].g,
  };
}

function mapTags(challenge: ChallengeResourceProps): TagProps[] {
  const { tags, whitelist } = challenge;

  if (isEmpty(tags)) return [];

  const mixed = tagUtils.mix(tags, whitelist || []);
  const formatted = tagUtils.format(mixed);
  const shuffled = tagUtils.shuffle(formatted);

  return shuffled;
}

function mapSteps(tags: any[]): TagProps[] {
  return tags.reduce((acc, tag, i: number) => {
    if (isString(tag)) {
      acc.push(tagUtils.map(tag, i));
      return acc;
    }

    acc.push(tagUtils.mapSpecial(tag, i));
    return acc;
  }, []);
}

function formatIOCode(
  code: IOCodeResourceProps[],
  programmingLanguages: ProgrammingLanguageResourceProps[]
): IOCodeProps[] {
  if (isEmpty(code)) return [];
  if (isEmpty(programmingLanguages)) return [];

  return code.map((c) => {
    const found = findProgrammingLanguage(programmingLanguages, c.p);
    if (!found) return { data: c.d, programmingLanguage: "" };

    return { data: c.d, programmingLanguage: found.type };
  });
}

function findProgrammingLanguage(
  programmingLanguages: ProgrammingLanguageResourceProps[],
  programmingLanguageId: number
): ProgrammingLanguageProps | undefined {
  const found = programmingLanguages.find(
    (l) => l.id === programmingLanguageId
  );

  if (!found) return undefined;
  return { ...found, id: found.id };
}

const challengeParser = {
  mix,
  map,
  list,
  hash,
};

export default challengeParser;
