// react
import { useState, useCallback, useEffect } from "react";

// components
import FormHeader from "components/form/FormHeader";
import { DropZoneAttentionWhore } from "components/DropZone";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// enums
import { BubbleType } from "enums/bubbleEnum";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";

// utils
import color from "styles/color";
import styled from "styled-components";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: ${color.dark.bg};
  color: ${color.grey.light};

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    h2 {
      font-size: 2em;
      margin-bottom: 20px;
      color: ${color.yellow};
      font-weight: bold;
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: space-between;
      list-style: none;
      margin: 20px 0;
      padding: 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        font-size: 1.1em;
        margin-bottom: 15px;

        div.index {
          width: 75px;
          height: 50px;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 100%;
            border-radius: 50%;
            background: ${color.grey.dark};
            color: ${color.white};
          }
        }

        p {
          flex: 1;
          margin: 0;
          padding: 0;
          text-align: left;
        }
      }
    }

    p {
      text-align: center;
    }
  }
`;

const DragMeTemplateEl = () => {
  return <p className="nerdfy_yellow pixelify_font_family">Drag me!</p>;
};

interface HowToPlayPresentationProps {
  close: () => void;
}

const HowToPlayPresentation = ({ close }: HowToPlayPresentationProps) => {
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [wrapper, setWrapper] = useState<HTMLDivElement | undefined>();
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());
  const handle = useCallback((node: HTMLDivElement) => {
    if (!node) return;
    setWrapper(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, [wrapper]);

  function bootstrap() {
    if (!wrapper) return;
    setTimeout(first, 500);
  }

  function first() {
    const payload = `Welcome! Be warned that almost everything here is made just by dragging something. May the odds be in our favor.`;
    setWhores([getWhore(payload, true)]);
  }

  function getWhore(payload: string, last?: boolean): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.get({
      last,
      payload,
      WhoreEl: DragMeTemplateEl,
      call: onAttentionWhoreCall,
      dropZoneBorderColor: "yellow",
      bubbleType: BubbleType.Warning,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function hideJarvisBubble() {
    if (!attentionWhore) return;

    setAttentionWhore({
      ...attentionWhore,
      show: false,
    });
  }

  return (
    <Wrapper
      ref={handle}
      className="animate__animated no-swipe animate__bounceInUp"
    >
      <div className="content">
        <h2>How To Play</h2>

        <ul>
          <li>
            <div className="index">
              <span>1</span>
            </div>{" "}
            <p>
              The bittles are <span className="nerdfy_green">healed</span> when
              you hit the code correctly and{" "}
              <span className="nerdfy_red">lose</span> HP if you don’t.
            </p>
          </li>

          <li>
            <div className="index">
              <span>2</span>
            </div>{" "}
            <p>
              Like a puzzle, you'll need to{" "}
              <span className="nerdfy_blue">put</span> the right pieces{" "}
              <span className="nerdfy_white">together</span> according to the{" "}
              <span className="nerdfy_orange">challenge</span>.
            </p>
          </li>

          <li>
            <div className="index">
              <span>3</span>
            </div>{" "}
            <p>
              <span className="nerdfy_yellow">Be careful</span>, some pieces may{" "}
              <span className="nerdfy_pink">hide</span> behind others, so you
              may need to <span className="nerdfy_white">search</span> to find
              them.
            </p>
          </li>
        </ul>

        <p>Save as many bittles as possible. Good Luck!</p>
      </div>

      <AttentionWhores whores={whores} />

      <BubbleHandler
        hide={hideJarvisBubble}
        show={!!attentionWhore.show}
        content={attentionWhore.payload}
        type={attentionWhore.bubbleType}
      />

      <DropZoneAttentionWhore />
      <FormHeader hideBrand close={close} position="bottom" />
    </Wrapper>
  );
};

export default HowToPlayPresentation;
