// react
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

// pages
import TermsPage from "pages/terms/TermsPage";
import ChallengePage from "pages/challenge/ChallengePage";
import ClassRoomContentPage from "pages/classRoom/ClassRoomContentPage";
import EmailActivationPage from "pages/emailActivation/EmailActivationPage";

// components
import NotFound from "components/NotFound";

// utils
import statusBarUtils from "utils/statusBarUtils";

// global css
import "theme/global.css";
import "animate.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

// swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

setupIonicReact();

// const IonAppWrapper = styled(IonApp)`
//   ion-modal {
//     margin-top: 20px; /* Status bar height on iOS 10 */
//     margin-top: constant(
//       safe-area-inset-top
//     ); /* Status bar height on iOS 11.0 */
//     margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
//   }
// `;

const App: React.FC = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(bootstrap, []);

  function bootstrap() {
    handleSplashScreen();
    handleStatusBar();
  }

  function handleSplashScreen() {
    // splashScreenUtils.hide();
  }

  function handleStatusBar() {
    statusBarUtils.dark();
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/" component={ChallengePage} />

          {/* <Route exact component={ClassRoomPage} path="/" /> */}

          <Route
            exact
            path="/classroom/:id/lang/:lang"
            component={ClassRoomContentPage}
          />

          <Route path="/doit/terms-conditions" component={TermsPage} exact />

          <Route
            exact
            path="/user/activate/:hash"
            component={EmailActivationPage}
          />

          <Route component={NotFound} />

          {/* <Route path="/" component={HomePage} exact /> */}

          {/* <Route
            path="/classroom/:id/lang/:lang"
            component={ClassRoomPage}
            exact
          /> */}
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
