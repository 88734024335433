export enum BubbleType {
  None = 0,
  God,
  Info,
  Quiz,
  Market,
  Sudo,
  Narrator,
  Alert,
  Warning,
}
