// react
import { useEffect, useState, useRef } from "react";

// components
import AttentionWhore from "components/attentionWhores/AttentionWhore";

// interfaces
import { AttentionWhoreProps } from "interfaces/attentionWhore";

// interact
import interact from "interactjs";
import type { Interactable } from "@interactjs/core/Interactable";

// utils
import audioUtils from "utils/audioUtils";
import { borderColorType } from "utils/dropUtils";

interface AttentionWhoresProps {
  whores: AttentionWhoreProps[];
  clear?: boolean;
  paused?: boolean;
  disabledDrop?: boolean;
  disableInactive?: boolean;
  listenMovement?(x: number, y: number): void;

  /*
    Só vai precisar dos inputs abaixo caso 
    haja MAIS DE UM componente DragWhores
    na view...
  */
  draggableClassName?: string;
  interactDropZone?: Interactable;
  interactDraggable?: Interactable;
  droppableAcceptClassName?: string;
  dropZoneBorderColor?: borderColorType;
}

/*
  1) O Attention Whore, diferente do drag whore, é sequencial, isso é, se você 
  tem 5 itens na tela, apenas será possível fazer o drop do primeiro, depois o segundo...

  2) O Drag whore, tem as mesmas funcionalidades que o AT, embora ele não seja sequencial,
  isso é, é possível fazer o drop de qualquer elemento, a qualquer momento.
*/

const AttentionWhores = ({
  clear,
  paused,
  whores,
  disabledDrop,
  listenMovement,
  disableInactive,
  draggableClassName = "attention_whore_draggable",
  droppableAcceptClassName = "attention_whore_droppable",
  interactDropZone = interact(".attention_whore_dropzone"),
  interactDraggable = interact(".attention_whore_draggable"),
}: AttentionWhoresProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const whoresRef = useRef<AttentionWhoreProps[]>([]);
  const whoresRemovedRef = useRef<{ [key: string]: boolean }>({});
  const interactDragRef = useRef<any>(null);
  const interactDropRef = useRef<any>(null);
  const disableDropRef = useRef<any>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startDrag.bind(null, listenMovement), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startDrop, []);
  useEffect(listenToWhores, [whores, activeIndex]);
  useEffect(listenToDisabledDrop, [disabledDrop]);

  function listenToDisabledDrop() {
    disableDropRef.current = disabledDrop;
  }

  function listenToWhores() {
    whoresRef.current = whores;
  }

  function startDrag(
    listenMovement: ((x: number, y: number) => void) | undefined
  ) {
    interactDragRef.current = interactDraggable.draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
        }),
      ],
      listeners: {
        move(event) {
          const { target } = event;
          const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

          target.style.top = `${y}px`;
          target.style.left = `${x}px`;

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);

          if (listenMovement) listenMovement(x, y);
        },
      },
    });
  }

  function startDrop() {
    interactDropRef.current = interactDropZone.dropzone({
      accept: `.${droppableAcceptClassName}`,
      overlap: 0.5,
      ondropactivate: function (event) {
        if (!event.relatedTarget.classList.contains("active")) return;
        event.target.classList.add("border_dashed_grey");
      },
      ondropdeactivate: function (event) {
        event.target.classList.remove("border_dashed_grey");
      },
      ondragenter: function (event) {
        const dragEl = event.relatedTarget;

        if (!dragEl.classList.contains("active")) return;

        event.target.classList.remove("border_dashed_grey");
        addDragEnterClassName(event.target, dragEl);
      },
      ondragleave: function (event) {
        removeAllDragEnterClassNames(event.target);
      },
      ondrop: function (event) {
        if (disableDropRef.current) return;

        const dragEl = event.relatedTarget;

        if (!dragEl) return;
        if (!dragEl.classList.contains("active")) return;

        removeAllDragEnterClassNames(event.target);

        const point = whoresRef.current.find((p) => p.id === dragEl.id);
        if (!point) return;

        setTimeout(() => {
          if (!point) return;
          if (!dragEl) return;

          dragEl.classList.add("animate__animated");
          dragEl.classList.add("animate__zoomOut");
          dragEl.addEventListener("animationend", onAnimationEnd);

          point.call(point);
          audioUtils.play.bubble();

          function onAnimationEnd() {
            if (!dragEl) return;
            dragEl.removeEventListener("animationend", onAnimationEnd);

            whoresRemovedRef.current[dragEl.id] = true;
            handleActiveIndex(whoresRemovedRef.current);
          }
        });
      },
    });
  }

  function addDragEnterClassName(dropEl: HTMLDivElement, dragEl: HTMLElement) {
    const { classList } = dragEl;
    let onenterClassName = "attention_whore_drop_zone_border_yellow";

    if (classList.contains("attention_whore_drop_zone_border_yellow"))
      onenterClassName = "border_dashed_yellow";
    else if (classList.contains("attention_whore_drop_zone_border_purple"))
      onenterClassName = "border_dashed_purple";
    else if (classList.contains("attention_whore_drop_zone_border_brown"))
      onenterClassName = "border_dashed_brown";
    else if (classList.contains("attention_whore_drop_zone_border_green"))
      onenterClassName = "border_dashed_green";
    else if (classList.contains("attention_whore_drop_zone_border_red"))
      onenterClassName = "border_dashed_red";
    else if (classList.contains("attention_whore_drop_zone_border_orange"))
      onenterClassName = "border_dashed_orange";
    else if (classList.contains("attention_whore_drop_zone_border_blue"))
      onenterClassName = "border_dashed_blue";
    else if (classList.contains("attention_whore_drop_zone_border_white"))
      onenterClassName = "border_dashed_white";
    else if (classList.contains("attention_whore_drop_zone_border_grey"))
      onenterClassName = "border_dashed_grey";
    else if (classList.contains("attention_whore_drop_zone_border_acai"))
      onenterClassName = "border_dashed_acai";
    else if (classList.contains("attention_whore_drop_zone_border_wine"))
      onenterClassName = "border_dashed_wine";
    else if (classList.contains("attention_whore_drop_zone_border_pink"))
      onenterClassName = "border_dashed_pink";
    else if (classList.contains("attention_whore_drop_zone_border_navy"))
      onenterClassName = "border_dashed_navy";

    dropEl.classList.add(onenterClassName);
  }

  function removeAllDragEnterClassNames(dropEl: HTMLDivElement) {
    const dropElClassList = dropEl.classList;

    dropElClassList.remove("border_dashed_yellow");
    dropElClassList.remove("border_dashed_purple");
    dropElClassList.remove("border_dashed_brown");
    dropElClassList.remove("border_dashed_orange");
    dropElClassList.remove("border_dashed_green");
    dropElClassList.remove("border_dashed_red");
    dropElClassList.remove("border_dashed_blue");
    dropElClassList.remove("border_dashed_white");
    dropElClassList.remove("border_dashed_grey");
    dropElClassList.remove("border_dashed_acai");
    dropElClassList.remove("border_dashed_pink");
    dropElClassList.remove("border_dashed_navy");
    dropElClassList.remove("border_dashed_wine");
  }

  function handleActiveIndex(removed: { [key: string]: boolean }) {
    const index = whoresRef.current.findIndex((point) => {
      if (!point.id) return false;
      return !removed[point.id];
    });

    if (index === -1) return setActiveIndex(0);
    setActiveIndex(index);
  }

  function destroyComponent() {
    destroyDragRef();
    destroyDropRef();
  }

  function destroyDragRef() {
    interactDragRef.current?.unset();
  }

  function destroyDropRef() {
    interactDropRef.current?.unset();
  }

  return (
    <>
      {whores.map((whore: AttentionWhoreProps, i: number) => {
        if (whore.id && whoresRemovedRef.current[whore.id]) return null;

        return (
          <AttentionWhore
            key={i}
            clear={clear}
            whore={whore}
            paused={paused}
            draggableClassName={draggableClassName}
            active={disableInactive ? true : activeIndex === i}
            droppableAcceptClassName={droppableAcceptClassName}
          />
        );
      })}
    </>
  );
};

export default AttentionWhores;
