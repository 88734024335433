// redux
import { ApplicationState } from "redux/store";

// interfaces
import {
  ChallengeProps,
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { AppProps } from "interfaces/app";
import { UserProps } from "interfaces/user";
import { AuthProps } from "interfaces/auth";
import { PlayerProps } from "interfaces/player";
import { PaymentProps } from "interfaces/payment";
import { ClassRoomProps } from "interfaces/classRoom";
import { NpcHashProps, NpcProps } from "interfaces/npc";
import { JarvisChatProps } from "interfaces/jarvisChat";
import { SectionsBriefProps } from "interfaces/sectionsBrief";
import { NextChallengeProps } from "interfaces/nextChallenge";
import { ChallengeCodeProps } from "interfaces/challengeCode";
import { ChallengeFlowProps } from "interfaces/challengeFlow";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { CheckpointsStateProps } from "interfaces/checkpoints";
import { ChallengeSectionProps } from "interfaces/challengeSection";
import { ChallengesMergedProps } from "interfaces/challengesMerged";
import { ClassRoomRatingSummaryProps } from "interfaces/classRoomRating";
import { ProgrammingLanguageProps } from "interfaces/programmingLanguage";

export const selector = {
  app() {
    return (state: ApplicationState): AppProps => state.app;
  },
  jarvisChats() {
    return (state: ApplicationState): JarvisChatProps[] => state.jarvisChats;
  },
  classRoom() {
    return (state: ApplicationState): ClassRoomProps => state.classRoom;
  },
  auth() {
    return (state: ApplicationState): AuthProps => state.auth;
  },
  user() {
    return (state: ApplicationState): UserProps =>
      state.auth && state.auth.user;
  },
  player() {
    return (state: ApplicationState): PlayerProps => state.player;
  },
  nextChallenge() {
    return (state: ApplicationState): NextChallengeProps => state.nextChallenge;
  },
  challenge() {
    return (state: ApplicationState): ChallengeProps => state.challenge;
  },
  challengesMerged() {
    return (state: ApplicationState): ChallengesMergedProps => state.challenges;
  },
  challenges() {
    return (state: ApplicationState): ChallengeCommonProps[] =>
      state.challenges.all;
  },
  challengesHash() {
    return (state: ApplicationState): ChallengesCommonHashProps =>
      state.challenges.hash;
  },
  challengeQuiz() {
    return (state: ApplicationState): ChallengeQuizProps => state.challengeQuiz;
  },
  challengeSection() {
    return (state: ApplicationState): ChallengeSectionProps =>
      state.challengeSection;
  },
  challengeSections() {
    return (state: ApplicationState): ChallengeSectionProps[] =>
      state.challengeSections;
  },
  challengeCode() {
    return (state: ApplicationState): ChallengeCodeProps => state.challengeCode;
  },
  programmingLanguages() {
    return (state: ApplicationState): ProgrammingLanguageProps[] =>
      state.programmingLanguages;
  },

  classRoomRatingSummary() {
    return (state: ApplicationState): ClassRoomRatingSummaryProps =>
      state.classRoomRatingSummary;
  },
  challengeFlow() {
    return (state: ApplicationState): ChallengeFlowProps => state.challengeFlow;
  },
  sectionsBrief() {
    return (state: ApplicationState): SectionsBriefProps => state.sectionsBrief;
  },
  checkpoints() {
    return (state: ApplicationState): CheckpointsStateProps =>
      state.checkpoints;
  },
  payment() {
    return (state: ApplicationState): PaymentProps => state.payment;
  },

  // npcs
  npc() {
    return (state: ApplicationState): NpcHashProps => state.npc;
  },
  jarvis() {
    return (state: ApplicationState): NpcProps => state.npc.jarvis;
  },
};
