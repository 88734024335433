// react
import { useRef, useEffect } from "react";

// handlers
import FeedbackBubble from "handlers/feedback/FeedbackBubble";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ChallengeFeedbackHandlerProps {
  challenge: ChallengeCommonProps;
}

const FeedbackHandler = ({ challenge }: ChallengeFeedbackHandlerProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { flowDone, flowFinished } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => destroyComponent, []);

  function destroyComponent() {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }

  return (
    <FeedbackBubble
      show={flowDone && !flowFinished}
      animate={flowDone && flowFinished}
    />
  );
};

export default FeedbackHandler;
