// interfaces
import { ChallengeProps } from "interfaces/challenge";

// utils
import codeUtils from "utils/code/codeUtils";

interface FlowInitValuesProps {
  flowInit: true;
}

interface FlowStartedValuesProps {
  flowStarted: true;
}

interface FlowFinishedValuesProps {
  flowSuccess: true;
  flowFinished: true;
}

interface FlowDoneValuesProps {
  frame: number;
  currentCode: string;

  // flow
  flowDone: true;
  flowSuccess: true;
  flowFinished: true;
}

interface FlowFailedValuesProps {
  frame: number;
  currentCode: string;

  // flow
  flowDone: true;
  flowFailed: true;
  flowFinished: true;
}

class ChallengeStateEntity {
  getFlowInitValues(): FlowInitValuesProps {
    return {
      flowInit: true,
    };
  }

  getFlowStartedValues(): FlowStartedValuesProps {
    return {
      flowStarted: true,
    };
  }

  getFlowFinishedValues(): FlowFinishedValuesProps {
    return {
      flowSuccess: true,
      flowFinished: true,
    };
  }

  getDoneValues(challenge: ChallengeProps): FlowDoneValuesProps {
    const { code, frame, currentCode } = challenge;

    return {
      flowDone: true,
      frame: frame + 1,
      flowSuccess: true,
      flowFinished: true,
      currentCode: codeUtils.simple.getFinished(currentCode, code),
    };
  }

  getFailedValues(challenge: ChallengeProps): FlowFailedValuesProps {
    const { code, frame } = challenge;

    return {
      flowDone: true,
      flowFailed: true,
      frame: frame + 1,
      flowFinished: true,
      currentCode: code.finished[0].data,
    };
  }
}

export default ChallengeStateEntity;
