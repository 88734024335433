// components
import ButtonDuolingo, {
  ButtonDuolingoTemplateProps,
} from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";

const ButtonDuolingoRed = ({
  height,
  onClick,
  children,
  disabled,
  widthFull,
  className,
}: ButtonDuolingoTemplateProps) => {
  return (
    <ButtonDuolingo
      bg={color.red}
      height={height}
      onClick={onClick}
      disabled={disabled}
      color={color.white}
      widthFull={widthFull}
      className={className}
      boxShadowColor="#df1127"
    >
      {children}
    </ButtonDuolingo>
  );
};

export default ButtonDuolingoRed;
