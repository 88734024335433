// interfaces
import {
  SectionsBriefProps,
  SectionBriefChallengeDoneProps,
} from "interfaces/sectionsBrief";
import { ChallengeCommonProps } from "interfaces/challenge";
import { ChallengeSectionProps } from "interfaces/challengeSection";

function map(
  sections: ChallengeSectionProps[],
  challenges: ChallengeCommonProps[]
): SectionsBriefProps {
  let lenCounter = 0;

  const reduced = sections.reduce((acc, section) => {
    const c = challenges.filter(
      (challenge) => challenge.challengeSectionId === section.id
    );

    const len = c.length;
    lenCounter += len;

    acc[section.id] = { len, done: 0, progress: 0 };
    return acc;
  }, {} as SectionsBriefProps);

  return {
    ...reduced,
    done: 0,
    progress: 0,
    len: lenCounter,
  };
}

function mapChallengesDone(
  challenges: { id: number; challengeSectionId: number; flowDone?: boolean }[]
): SectionBriefChallengeDoneProps[] {
  return challenges
    .filter((challenge) => challenge.flowDone)
    .map((challenge) => ({
      id: challenge.id,
      sectionId: challenge.challengeSectionId,
    }));
}

const sectionsBriefParser = {
  map,
  mapChallengesDone,
};

export default sectionsBriefParser;
