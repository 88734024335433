// utils
import axios from "axios";
import authUtils from "utils/authUtils";

class RequestCoreService {
  accessToken: string;
  headers: any;

  constructor() {
    this.accessToken = authUtils.getAccessToken();
  }

  post(resource: string, data?: unknown): Promise<any> {
    this.setHeaders();

    const { headers } = this;
    const method = "post";

    return this._request({
      resource,
      headers,
      method,
      data,
    });
  }

  put(resource: string, data?: unknown): Promise<any> {
    this.setHeaders();

    const { headers } = this;
    const method = "put";

    return this._request({
      resource,
      headers,
      method,
      data,
    });
  }

  get(resource: string): Promise<any> {
    this.setHeaders();

    const { headers } = this;
    const method = "get";

    return this._request({
      resource,
      headers,
      method,
    });
  }

  remove(resource: string): Promise<any> {
    this.setHeaders();

    const { headers } = this;
    const method = "delete";

    return this._request({
      resource,
      headers,
      method,
    });
  }

  postWithoutAuthorization(resource: string, data?: unknown): Promise<any> {
    this.setHeadersWithoutAuthorization();

    const { headers } = this;
    const method = "post";

    return this._request({
      resource,
      headers,
      method,
      data,
    });
  }

  async _request({ resource, data, method, headers }: any): Promise<any> {
    const params: any = {
      method,
      headers,
      timeout: 10000,
      url: `${process.env.REACT_APP_API}/${resource}`,
    };

    if (data) params.data = data;

    return axios(params)
      .then((res: any) => res.data)
      .catch((err: any) => {
        if (!err.response) {
          const response = { data: { status: "timeout" } };
          return Promise.reject(response);
        }

        const { response } = err;
        return Promise.reject(response.data);
      });
  }

  setHeaders({
    Accept = "application/json",
    ContentType = "application/json",
  } = {}) {
    this.headers = {
      Accept,
      "Content-Type": ContentType,
    };

    if (this.accessToken)
      this.headers.authorization = `Bearer ${this.accessToken}`;
  }

  setHeadersWithoutAuthorization({
    Accept = "application/json",
    ContentType = "application/json",
  } = {}) {
    this.headers = {
      Accept,
      "Content-Type": ContentType,
    };
  }
}

export default RequestCoreService;
