// react
import { useState, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import DragWhores from "components/dragWhores/DragWhores";
import ChallengeButton from "handlers/challengeButtons/ChallengeButton";

// enums
import { BubbleType } from "enums/bubbleEnum";

// handlers
import BubbleHandler from "handlers/bubble/BubbleHandler";

// interfaces
import {
  SudoStartedFlowProps,
  CurrentDragWhoreProps,
} from "handlers/challengeButtons/sudo/SudoHandler";
import { DragWhoreProps } from "interfaces/dragWhore";
// components
import { DragWhoreTemplateElProps } from "components/dragWhores/DragWhore";

// utils
import color from "styles/color";
import interact from "interactjs";
import animationUtils from "utils/animationUtils";
import dragWhoreUtils from "utils/dragWhoreUtils";

const DragWhoreSudoTemplateEl = ({ whore }: DragWhoreTemplateElProps) => {
  const payload = whore.payload as CurrentDragWhoreProps;

  return (
    <p className={`nerdfy_green pixelify_font_family sudo_cmd`}>
      {payload.content}
    </p>
  );
};

const Sudo = ({
  paused,
  challenge,
  getWhores,
  getContent,
}: SudoStartedFlowProps) => {
  const dispatch = useDispatch();
  const { flowInit, flowFinished } = challenge;
  const [animation, setAnimation] = useState("");
  const [whores, setWhores] = useState<DragWhoreProps[][]>([]);
  const [whore, setWhore] = useState<CurrentDragWhoreProps>(
    dragWhoreUtils.fake()
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(init, [flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(buttonZoomOut, [flowFinished]);

  function init() {
    if (!flowInit) return;

    const w = {
      ...whore,
      show: true,
      last: true,
      bubbleType: BubbleType.Sudo,
      payload: { speech: challenge.speeches[0] },
    };

    setTimeout(() => setWhore(w), 500);
    setTimeout(() => dispatch(challengeActions.async.start()), 1000);
  }

  function buttonZoomOut() {
    if (!animation) return;
    if (!flowFinished) return;
    if (animation === "animate__zoomOut") return;

    setAnimation("animate__zoomOut");
  }

  function hideBubble() {
    if (!whore) return;
    if (whore.last) displayButton();

    setWhore({
      ...whore,
      show: false,
    });
  }

  function displayButton() {
    setAnimation(animationUtils.getRandom());
  }

  function onClick() {
    if (animation === "animate__zoomOut") return;

    displayWhores();
    setTimeout(() => setAnimation("animate__zoomOut"));
  }

  function displayWhores() {
    setWhores([...whores, getWhores("desc", onDragWhoreCall)]);
  }

  function onDragWhoreCall(whore: DragWhoreProps) {
    setWhore({ ...whore, show: true });
  }

  return (
    <>
      {!!animation && (
        <ChallengeButton
          onClick={onClick}
          color={color.white}
          label={
            <span className="nerdfy_green pixelify_font_family">desc</span>
          }
          customClassName={`animate__animated no-swipe ${animation}`}
        />
      )}

      {whores.map((whores, i: number) => (
        <DragWhores
          key={i}
          whores={whores}
          paused={paused}
          clear={challenge.flowDone}
          dropZoneBorderColor="white"
          disabledDrop={!!whore.show}
          TemplateEl={DragWhoreSudoTemplateEl}
          draggableClassName="drag_whore_dropzone_sudo"
          droppableAcceptClassName="drag_whore_droppable_sudo"
          interactDraggable={interact(".drag_whore_dropzone_sudo")}
          interactDropZone={interact(".drag_whore_dropzone_sudo")}
        />
      ))}

      <BubbleHandler
        hide={hideBubble}
        show={!!whore.show}
        type={whore.bubbleType}
        content={getContent(whore)}
      />
    </>
  );
};

export default Sudo;
