// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { PaymentProps } from "interfaces/payment";

const initialState: PaymentProps = {
  url: "",
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<PaymentProps>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
