class JarvisEntity {
  name: string;
  static defaultName = "Gaspar Turin";
  static unknownName = "Unknown Voice";

  constructor({ name }: { name: string }) {
    this.name = name;
  }

  has(): boolean {
    return !!(this.name && this.name !== JarvisEntity.unknownName);
  }
}

export default JarvisEntity;
