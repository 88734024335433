// react
import { IonRippleEffect } from "@ionic/react";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  opacity: 0.9;
  background: ${color.dark.bg};
  padding: 15px;

  .fake_btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .stats {
      position: absolute;
      bottom: 12px;
    }

    > p {
      font-size: 1.8em;
      text-align: center;
      margin: 10px 20px;

      &.desc {
        color: ${color.grey.middle};
      }
    }
  }
`;

interface ChallengeButtonProps {
  loading: boolean;
  onClick: () => void;
}

const ChallengeStartButton = ({ loading, onClick }: ChallengeButtonProps) => {
  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable">
      <IonRippleEffect style={{ color: color.yellow }} />

      <div className="fake_btn blink">
        <p className="nerdfy_white">Press to Start</p>
        <p className="desc">{loading ? "Loading" : "We're almost there..."}</p>
      </div>
    </Wrapper>
  );
};

interface ChallengeStartButtonProps extends ChallengeButtonProps {
  challenge: ChallengeCommonProps;
}

export const ChallengeContinueButton = ({
  loading,
  onClick,
  challenge,
}: ChallengeStartButtonProps) => {
  function click() {
    setTimeout(onClick, 50);
  }

  return (
    <Wrapper onClick={click} className="ion-activatable">
      <IonRippleEffect style={{ color: color.blue }} />

      <div className="fake_btn blink">
        {challenge.flowDone ? (
          <>
            <p className="nerdfy_green">Press to View Stats</p>
            <p className="desc">
              {loading ? (
                "Loading..."
              ) : (
                <span>
                  Come back <span className="nerdfy_yellow">tomorrow</span> to{" "}
                  <span className="nerdfy_white">play</span> again.
                </span>
              )}
            </p>
          </>
        ) : (
          <>
            <p className="nerdfy_green">Press to Start</p>
            <p className="desc">{loading ? "Loading..." : "Are you ready?"}</p>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default ChallengeStartButton;
