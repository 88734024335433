// redux
import { combineReducers } from "redux";

// reducers
import appReducer from "redux/slices/appSlice";
import npcReducer from "redux/slices/npcSlice";
import authReducer from "redux/slices/authSlice";
import playerReducer from "redux/slices/playerSlice";
import paymentReducer from "redux/slices/paymentSlice";
import classRoomReducer from "redux/slices/classRoomSlice";
import challengeReducer from "redux/slices/challengeSlice";
import challengesReducer from "redux/slices/challengesSlice";
import jarvisChatsReducer from "redux/slices/jarvisChatsSlice";
import checkpointsReducer from "redux/slices/checkpointsSlice";
import sectionsBriefReducer from "redux/slices/sectionsBriefSlice";
import nextChallengeReducer from "redux/slices/nextChallengeSlice";
import challengeCodeReducer from "redux/slices/challengeCodeSlice";
import challengeQuizReducer from "redux/slices/challengeQuizSlice";
import challengeFlowReducer from "redux/slices/challengeFlowSlice";
import challengeSectionReducer from "redux/slices/challengeSectionSlice";
import challengeSectionsReducer from "redux/slices/challengeSectionsSlice";
import programmingLanguagesReducer from "redux/slices/programmingLanguagesSlice";
import classRoomRatingSummaryReducer from "redux/slices/classRoomRatingSummarySlice";

const appReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  npc: npcReducer,
  player: playerReducer,
  payment: paymentReducer,
  checkpoints: checkpointsReducer,
  classRoom: classRoomReducer,
  challenge: challengeReducer,
  nextChallenge: nextChallengeReducer,
  challengeCode: challengeCodeReducer,
  challengeSection: challengeSectionReducer,
  challengeSections: challengeSectionsReducer,
  programmingLanguages: programmingLanguagesReducer,
  jarvisChats: jarvisChatsReducer,
  challengeFlow: challengeFlowReducer,
  classRoomRatingSummary: classRoomRatingSummaryReducer,
  challengeQuiz: challengeQuizReducer,
  challenges: challengesReducer,
  sectionsBrief: sectionsBriefReducer,
});

export default appReducers;
