// services
import UserAuthService from "services/auth/UserAuthService";

// interfaces
import { UserSignExternalProps } from "interfaces/user";

// errors
import SignInError from "errors/SignInError";
import GoogleEmailNotFoundError from "errors/GoogleEmailNotFoundError";

// firebase
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

export default class GoogleAuthService {
  async login(): Promise<UserSignExternalProps> {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    return signInWithPopup(auth, provider).then(async (result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);

      if (!credential) return Promise.reject(new SignInError());
      if (!credential.accessToken) return Promise.reject(new SignInError());

      const data = await new UserAuthService(result).format();
      if (!data.email) return Promise.reject(new GoogleEmailNotFoundError());

      return data;
    });
  }
}
