// services
import RequestCoreService from "services/core/RequestCoreService";

// errors
import BadRequestError from "errors/BadRequestError";

// interfaces
import { AuthResourceProps } from "interfaces/auth";
import {
  UserSignProps,
  UserResourceProps,
  UserSignExternalProps,
} from "interfaces/user";

export interface UserNamesProps {
  givenName: string;
  firstName: string;
  jarvisName: string;
  lastName?: string;
}

class UserService {
  private _resource = "user";
  private _request: RequestCoreService;

  constructor() {
    this._request = new RequestCoreService();
  }

  signIn(user: UserSignProps): Promise<{ data: AuthResourceProps }> {
    const resource = `${this._resource}/login`;
    return this._request.postWithoutAuthorization(resource, user);
  }

  signUp(data: UserSignProps): Promise<{ data: AuthResourceProps }> {
    const resource = `${this._resource}/signup`;
    return this._request.postWithoutAuthorization(resource, data);
  }

  signInExternal(
    user: UserSignExternalProps
  ): Promise<{ data: AuthResourceProps }> {
    const resource = `${this._resource}/login/external`;
    return this._request.postWithoutAuthorization(resource, user);
  }

  get(): Promise<{ data: UserResourceProps }> {
    const resource = `${this._resource}`;
    return this._request.get(resource);
  }

  updateNames(data: UserNamesProps): Promise<unknown> {
    if (!data.firstName) return Promise.reject(new BadRequestError());
    if (!data.givenName) return Promise.reject(new BadRequestError());
    if (!data.jarvisName) return Promise.reject(new BadRequestError());

    const resource = `${this._resource}/usernames`;
    return this._request.put(resource, data);
  }

  sendEmailActivation(): Promise<unknown> {
    const resource = `${this._resource}/email-activation/send`;
    return this._request.post(resource);
  }

  activateEmail({
    signUpValidationCode,
  }: {
    signUpValidationCode: string;
  }): Promise<unknown> {
    const resource = `${this._resource}/email-activation/confirm`;
    return this._request.postWithoutAuthorization(resource, {
      signUpValidationCode,
    });
  }
}

export default UserService;
