// utils
import c from "styles/color";

interface SvgProps {
  width?: string;
  color?: string;
  height?: string;
}

const CrossSvg = ({ width = "18", height = "18", color = c.red }: SvgProps) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 64 64"
    >
      <g>
        <path
          d="m63.437 10.362-9.8-9.8c-.75-.75-1.966-.75-2.717 0l-18.92 18.922-18.921-18.921c-.75-.75-1.966-.75-2.717 0l-9.8 9.8c-.75.75-.75 1.966 0 2.717l18.922 18.92-18.921 18.921c-.75.75-.75 1.966 0 2.717l9.8 9.8c.75.75 1.966.75 2.717 0l18.92-18.922 18.921 18.921c.75.75 1.966.75 2.717 0l9.8-9.8c.75-.75.75-1.966 0-2.717l-18.922-18.92 18.921-18.921c.751-.749.751-1.966 0-2.717z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CrossSvg;
