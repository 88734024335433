// components
import StatsWidget from "widgets/StatsWidget";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import c from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div<{ width: string; height: string; color: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  opacity: 0.7;
  background: ${c.dark.bg};

  > .stats {
    position: absolute;
    bottom: 12px;
    left: 12px;
  }

  > .description {
    position: absolute;
    bottom: 12px;
    right: 12px;
    left: 12px;
    font-size: 1.3em;
    margin: 0;
    text-align: right;
    opacity: 0.6;
  }

  > .content {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    z-index: 9999;

    > .button_ripple_effect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-radius: 0;
      font-size: 2em;
      border: 2px dashed ${(props) => props.color};

      span {
        text-align: center;
      }
    }
  }
`;

interface PressButtonProps {
  title: string;
  onClick: () => void;
  width?: string;
  height?: string;
  color?: string;
  description?: JSX.Element;
}

const PressButton = ({
  title,
  onClick,
  description,
  width = "90%",
  height = "250px",
  color = c.yellow,
}: PressButtonProps) => {
  function handleOnClick() {
    setTimeout(onClick, 250);
  }

  return (
    <Wrapper {...{ width, height, color }}>
      <div className="content blink no-swipe">
        <ButtonRippleEffect
          color={color}
          onClick={handleOnClick}
          className="button_ripple_effect"
        >
          <span>{title}</span>
        </ButtonRippleEffect>
      </div>

      <div className="stats animate__animated animate__bounceInRight">
        <StatsWidget />
      </div>
    </Wrapper>
  );
};

export default PressButton;
