// firebase
import { getAuth, signInWithPopup, GithubAuthProvider } from "firebase/auth";

// errors
import SignInError from "errors/SignInError";
import GithubEmailNotFoundError from "errors/GithubEmailNotFoundError";

// interfaces
import { UserSignExternalProps } from "interfaces/user";

// services
import UserAuthService from "services/auth/UserAuthService";

interface GithubEmailResponse {
  email: string;
  primary: boolean;
  verified: boolean;
  visibility: string;
}

export default class GithubAuthService {
  async login(): Promise<UserSignExternalProps> {
    const auth = getAuth();
    const provider = new GithubAuthProvider();

    return signInWithPopup(auth, provider).then((result) => {
      const credential = GithubAuthProvider.credentialFromResult(result);

      if (!credential) return Promise.reject(new SignInError());
      if (!credential.accessToken) return Promise.reject(new SignInError());

      return this.getUserData(credential.accessToken).then((user) => {
        const data = this.getPrimaryEmail(user);

        if (!data) return Promise.reject(new GithubEmailNotFoundError());
        if (!data.email) return Promise.reject(new GithubEmailNotFoundError());

        return new UserAuthService(result).format().then((res) => {
          return {
            ...res,
            email: data.email,
          };
        });
      });
    });
  }

  private async getUserData(
    accessToken: string
  ): Promise<GithubEmailResponse[]> {
    const response = await fetch("https://api.github.com/user/emails", {
      headers: {
        Authorization: `token ${accessToken}`,
      },
    });

    if (!response.ok) throw new Error("Failed to fetch user data from GitHub");
    return await response.json();
  }

  private getPrimaryEmail(
    emails: GithubEmailResponse[]
  ): GithubEmailResponse | undefined {
    return emails.find((email) => email.primary);
  }
}
