// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  position: relative;
  margin: 0;
  padding: 0;
  background: ${color.grey.dark};
  color: ${color.grey.stronger};

  p {
    all: unset;
    font-size: 1em;
  }

  span {
    display: inline-block;
    font-size: 1em;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <p>
        Bittle @2024. Made with <span className="nerdfy_red">♥</span>.
      </p>
      {/* <span className="nerdfy_red">♥</span>  */}
    </Wrapper>
  );
};

export default Footer;
