// interfaces
import { AuthProps } from "interfaces/auth";
import { ChallengeCommonProps } from "interfaces/challenge";
import { FeedbackClassRoomRatingsFirebaseProps } from "interfaces/feedback";

// utils
import { serverTimestamp } from "firebase/firestore";

function map(
  auth: AuthProps,
  challenge: ChallengeCommonProps,
  data: { rating: number; message: string }
): FeedbackClassRoomRatingsFirebaseProps {
  const { user } = auth;

  return {
    userId: user.id,
    rating: data.rating,
    message: data.message,
    userGenre: user.genre,
    anonymous: auth.anonymous,
    languageId: challenge.languageId,
    classRoomId: challenge.classRoomId,

    // timestamps
    createdAt: serverTimestamp(),
  };
}

const feedbackClassRoomRatingParser = {
  map,
};

export default feedbackClassRoomRatingParser;
