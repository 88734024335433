// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreWarning = ({ active }: AttentionWhoreElProps) => {
  return (
    <span
      className={`jarvis_alert jarvis_alert_big nerdfy_yellow pixelify_font_family ${
        active ? "nerdfy_yellow" : "nerdfy_grey_stronger"
      }`}
    >
      !
    </span>
  );
};

export default IconWhoreWarning;
