// react
import { useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { playerActions } from "redux/slices/playerSlice";

// utils
import dateUtils from "utils/dateUtils";

/*
  TODO:

  1. Verify if yesterday is weekend. (ok)
  1.1 If it is, check if player won on last Friday. (ok)
  1.2 If player didn't win, reset streak. (ok)
  1.3 Alert player about the streak reset.
  
  2. Verify if yesterday isn't weekend. (ok)
  2.1 If it isn't, check if player won yesterday. (ok)
  2.2 If player didn't win, reset streak. (ok)
  2.3 Alert player about the streak reset.
*/

const PlayerStreakHandler = () => {
  const dispatch = useDispatch();
  const alreadyHandled = useRef(false);
  const { last, streak } = useSelector(s.player());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handle, [last, streak]);

  function handle() {
    if (!last) return;
    if (!streak) return;
    if (alreadyHandled.current) return;

    alreadyHandled.current = true;
    const { reset, isEqual, isWeekend, getToday, getYesterday, getLastFriday } =
      dateUtils;

    const l = reset(new Date(last));
    const yesterday = reset(getYesterday());

    if (isEqual(l, reset(getToday()))) return;

    if (!isWeekend(yesterday)) {
      if (!isEqual(l, yesterday)) {
        resetStreak();
        return;
      }

      return;
    }

    if (!isEqual(l, reset(getLastFriday(l)))) {
      resetStreak();
      return;
    }
  }

  function resetStreak() {
    dispatch(playerActions.async.update({ streak: 0 }));
  }

  return null;
};

export default PlayerStreakHandler;
