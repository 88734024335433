// redux
import { playerActions } from "redux/slices/playerSlice";
import { createListenerMiddleware } from "@reduxjs/toolkit";

// interfaces
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

// services
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";

// utils
import stateUtils from "redux/utils/stateUtils";

const playerMiddleware = createListenerMiddleware();

playerMiddleware.startListening({
  actionCreator: playerActions.async.update,
  effect: async ({ payload }, listenerApi) => {
    const { auth } = stateUtils.get(listenerApi);

    if (!auth) return;
    if (!auth.user) return;
    if (!auth.user.id) return;

    updatePlayerFirebase(auth.user.id, payload);
  },
});

// private

function updatePlayerFirebase(
  userId: number | string,
  player: PlayerFirebaseUpdateProps
) {
  if (!userId) return;

  const playerFirebaseService = new PlayerFirebaseService();
  playerFirebaseService.update(userId, player);
}

export default playerMiddleware;
