// formik
import { Formik, Form, FormikProps } from "formik";

// components
import FormHeader from "components/form/FormHeader";
import FormikInput from "components/formik/FormikInput";
import FormikAutoFill from "components/formik/FormikAutoFill";

// utils
import * as yup from "yup";
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;

    flex-direction: column;

    form {
      flex: 1;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
        color: ${color.grey.light};
      }
    }
  }
`;

interface JarvisNameProps {
  jarvisName: string;
}

interface JarvisNamingProps {
  goBack(): void;
  goForward(): void;
  disabledBack: boolean;
  proceedDisabled: boolean;
  onSubmit(name: string): void;
}

const JarvisNaming = ({
  goBack,
  onSubmit,
  goForward,
  disabledBack,
  proceedDisabled,
}: JarvisNamingProps) => {
  function getSchemaValidation() {
    const jarvisName = yup
      .string()
      .required("Field is required")
      .matches(
        /^[a-zA-ZÀ-ÿ\s]{2,30}$/,
        "Name must be 2-30 characters long and contain only letters and spaces"
      );

    return yup.object({
      jarvisName,
    });
  }

  function getInitialValues(): JarvisNameProps {
    return {
      jarvisName: "Gaspar Turin",
    };
  }

  function submit(data: JarvisNameProps) {
    onSubmit(data.jarvisName);
  }

  function go(submit: () => void) {
    submit();
    goForward();
  }

  return (
    <Wrapper>
      <Formik
        onSubmit={submit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getInitialValues()}
        validationSchema={getSchemaValidation()}
      >
        {(props: FormikProps<JarvisNameProps>) => {
          return (
            <div className="body">
              <FormHeader
                back={goBack}
                disabled={proceedDisabled}
                disabledBack={disabledBack}
                proceed={go.bind(null, props.submitForm)}
              />

              <Form noValidate>
                <FormikAutoFill
                  field="name"
                  props={props}
                  identifier="jarvisName"
                />

                <div className="content"></div>

                <div className="user_input">
                  <FormikInput
                    label="Name"
                    id="jarvisName"
                    name="jarvisName"
                    autocorrect="off"
                    placeholder="Type"
                    autocapitalize="off"
                    bgColor={color.grey.dark}
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default JarvisNaming;
