// utils
import styled from "styled-components";
import color from "styles/color";

interface WrapperProps {
  alignLeft?: boolean;
}

const Wrapper = styled.p<WrapperProps>`
  display: block;
  width: 100%;
  font-size: 13px;
  text-align: center;
  margin: 10px 0;
  color: ${color.red} !important;
  height: 20px;

  ${(props) => `
    ${props && props.alignLeft && `text-align: left;`}
  `}
`;

interface ErrorFeedbackProps {
  err: string;
  alignLeft?: boolean;
}

const ErrorFeedback = (props: ErrorFeedbackProps) => {
  return <Wrapper {...props}>{props.err}</Wrapper>;
};

export default ErrorFeedback;
