// utils
import styled from "styled-components";

const StatusBarWrapper = styled.div`
  height: 45px;
  width: 100%;
`;

const ChallengeStatusBar = () => {
  return <StatusBarWrapper></StatusBarWrapper>;
};

export default ChallengeStatusBar;
