// interfaces
import { PlayerProps } from "interfaces/player";
import { PlayerDaysFirebaseProps } from "interfaces/playerFirebase";

// utils
import { increment, FieldValue, serverTimestamp } from "firebase/firestore";

interface DoneValuesProps {
  last: FieldValue;
  streak: FieldValue;
  checkpoint: FieldValue;
  days: PlayerDaysFirebaseProps;

  maxStreak?: FieldValue;
}

interface FailedValuesProps {
  streak: number;
  last: FieldValue;
  killed: FieldValue;
  checkpoint: FieldValue;
}

class PlayerFirebaseEntity {
  getHelpValues(): { help: FieldValue } {
    return { help: increment(1) };
  }

  getMissedValues(): { missed: FieldValue } {
    return { missed: increment(1) };
  }

  getDoneValues(player: PlayerProps): DoneValuesProps {
    const data: DoneValuesProps = {
      streak: increment(1),
      last: serverTimestamp(),
      checkpoint: increment(1),
      days: { [player.checkpoint + 1]: true },
    };

    if (player.streak === player.maxStreak) data.maxStreak = increment(1);

    return data;
  }

  getFailedValues(): FailedValuesProps {
    return {
      streak: 0,
      killed: increment(1),
      last: serverTimestamp(),
      checkpoint: increment(1),
    };
  }
}

export default PlayerFirebaseEntity;
