function isWeekend(date: Date): boolean {
  const day = date.getDay();
  return day === 0 || day === 6;
}

function isEqual(date: Date, dateToCompare: Date): boolean {
  return date.getTime() === dateToCompare.getTime();
}

function reset(date: Date): Date {
  date.setHours(0, 0, 0, 0);
  return date;
}

function getYesterday(): Date {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  return yesterday;
}

function getToday(): Date {
  return new Date();
}

function getLastFriday(date: Date): Date {
  const day = date.getDay();
  const diff = day >= 5 ? day - 5 : 7 - (5 - day);
  const lastFriday = new Date(date);
  lastFriday.setDate(date.getDate() - diff);

  return lastFriday;
}

const dateUtils = {
  reset,
  isEqual,
  getToday,
  isWeekend,
  getYesterday,
  getLastFriday,
};

export default dateUtils;
