const color = {
  primary: "#9D6FB0",
  secondary: "#3399ff",
  tertiary: "#F14C5D",
  acai: "#E31986",
  red: "#F14C5D",
  orange: "#E37A3E",
  green: "#2dd25c",
  blue: "#3399ff",
  purple: "#9D6FB0",
  yellow: "#F8CB21",
  brown: "#956840",
  white: "#FFFFFF",
  pink: "#D16B9A",
  navy: "#1D5AE5",
  wine: "#9D1D11",
  gold: "#A68854",
  grey: {
    light: "#979ca6",
    middle: "#707070",
    strong: "#636363",
    stronger: "#393939",
    dark: "#1b1b1b",
  },
  dark: {
    bg: "#181618",
    // bg: "#212121",
    // bg: "#F8CD80",
  },
  brand: {
    nerdbook: "#3c5a98",
    nerdflix: "#df0912",
    nerdX: "#015289",
    nerdtel: "#03c7fd",
  },

  // theme colors
  danger: "#707070",
};

export default color;
