// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

const BubbleAlert = ({
  hide,
  name,
  footer,
  content,
  subHeader,
  onAnimationEnd,
  finishedTyping,
  customClasseName,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_yellow"
      onAnimationEnd={onAnimationEnd}
      finishedTyping={finishedTyping}
      customClasseName={customClasseName}
      footerIcon={
        <span className="jarvis_alert jarvis_alert_middle nerdfy_red pixelify_font_family">
          !
        </span>
      }
    />
  );
};

export default BubbleAlert;
