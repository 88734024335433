// react
import { useEffect } from "react";

//redux
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { npcActions } from "redux/slices/npcSlice";
import { playerActions } from "redux/slices/playerSlice";
import { checkpointsActions } from "redux/slices/checkpointsSlice";

// parsers
import playerParser from "parsers/playerParser";

// enums
import { CheckpointsEnum } from "enums/checkpointsEnum";

// firebase
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

// interfaces
import {
  JarvisFirebaseProps,
  PlayerFirebaseProps,
} from "interfaces/playerFirebase";
import { UserProps } from "interfaces/user";
import { CheckpointsProps } from "interfaces/checkpoints";

// services
import FirebaseCoreService from "services/core/FirebaseCoreService";

// utils
import isEmpty from "lodash/isEmpty";

interface PlayerFirebaseSyncProps {
  user: UserProps;
}

const PlayerFirebaseSync = ({ user }: PlayerFirebaseSyncProps) => {
  const dispatch = useDispatch();
  const [snapshot] = useDocument(
    doc(FirebaseCoreService.getDatabase(), "players", String(user.id))
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(sync, [snapshot]);

  function sync() {
    syncPlayerFirebase(snapshot?.data() as PlayerFirebaseProps, dispatch);
  }

  return null;
};

export function syncPlayerFirebase(
  player: PlayerFirebaseProps,
  dispatch: Dispatch
) {
  if (isEmpty(player)) return;

  const p = playerParser.map(player);

  updateNpc(player.jarvis, dispatch);
  updateCheckpoint(player, dispatch);
  dispatch(playerActions.update(p));
}

function updateCheckpoint(player: PlayerFirebaseProps, dispatch: Dispatch) {
  if (isEmpty(player)) return;

  const checkpoint = player.checkpoints as CheckpointsProps | undefined;
  if (!checkpoint) return;

  const introIoCode = checkpoint[CheckpointsEnum.IntroIOCode];

  if (introIoCode)
    dispatch(checkpointsActions.set({ [CheckpointsEnum.IntroIOCode]: true }));
}

function updateNpc(
  jarvis: JarvisFirebaseProps | undefined,
  dispatch: Dispatch
) {
  if (!jarvis) return;
  if (!jarvis.name) return;

  dispatch(npcActions.updateJarvisName(jarvis.name));
}

export default PlayerFirebaseSync;
