// react
import { useState, useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// interfaces
import {
  AttentionWhoreProps,
  CurrentAttentionWhoreProps,
} from "interfaces/attentionWhore";
import { ChallengeProps } from "interfaces/challenge";

// components
import ChallengeButton from "handlers/challengeButtons/ChallengeButton";
import AttentionWhores from "components/attentionWhores/AttentionWhores";

// presentations
import IOCodePresentation from "handlers/challengeButtons/ioCode/IOCodePresentation";

// svg
import CodeTagSvg from "svg/CodeTagSvg";

// utils
import color from "styles/color";
import isEmpty from "lodash/isEmpty";
import animationUtils from "utils/animationUtils";
import attentionWhoreUtils from "utils/attentionWhoreUtils";

interface LabsHandlerProps {
  paused: boolean;
  challenge: ChallengeProps;
  wrapper: HTMLDivElement | null;
}

const IOCodeHandler = ({ paused, wrapper, challenge }: LabsHandlerProps) => {
  const dispatch = useDispatch();
  const { input, output, flowInit, flowFinished } = challenge;
  const [animation, setAnimation] = useState("");
  const [whores, setWhores] = useState<AttentionWhoreProps[]>([]);
  const [attentionWhore, setAttentionWhore] =
    useState<CurrentAttentionWhoreProps>(attentionWhoreUtils.fake());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startWhores, [input, output, flowInit]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(buttonZoomOut, [flowFinished]);

  function startWhores() {
    if (!flowInit) return;
    if (isEmpty(input) && isEmpty(output)) return;

    setTimeout(displayWhores, 800);
  }

  function buttonZoomOut() {
    if (!animation) return;
    if (!flowFinished) return;
    if (animation === "animate__zoomOut") return;

    setAnimation("animate__zoomOut");
  }

  function displayWhores() {
    setWhores([...getWhores()]);
  }

  function getWhores(): AttentionWhoreProps[] {
    if (!input) return [attentionWhoreUtils.fake()];
    if (!output) return [attentionWhoreUtils.fake()];

    const i = input && input[0].data;
    const o = output && output[0].data;

    if (!i && !o) return [attentionWhoreUtils.fake()];
    return [getWhore()];
  }

  function getWhore(): AttentionWhoreProps {
    if (!wrapper) return attentionWhoreUtils.fake();

    return attentionWhoreUtils.getIO({
      last: true,
      payload: "",
      call: onAttentionWhoreCall,
      wrapperWidth: wrapper.clientWidth,
      wrapperHeight: wrapper.clientHeight,
    });
  }

  function onAttentionWhoreCall(attentionWhore: AttentionWhoreProps) {
    freeze();
    setAttentionWhore({ ...attentionWhore, show: true });
  }

  function finishedPresentation() {
    displayButton();
    setAttentionWhore({ ...attentionWhore, show: false });
  }

  function displayButton() {
    setAnimation(animationUtils.getRandom());
  }

  function hideButton() {
    setTimeout(() => setAnimation("animate__zoomOut"));
  }

  function onClick() {
    if (animation === "animate__zoomOut") return;

    displayWhores();
    setTimeout(hideButton);
  }

  function unfreeze() {
    setTimeout(() => dispatch(challengeFlowActions.unfreeze()));
  }

  function freeze() {
    setTimeout(() => dispatch(challengeFlowActions.freeze()), 800);
  }

  return (
    <>
      {!!animation && (
        <ChallengeButton
          bottom={230}
          onClick={onClick}
          color={color.pink}
          label={
            <span>
              <CodeTagSvg color={color.pink} />
            </span>
          }
          customClassName={`animate__animated ${animation}`}
        />
      )}

      <IOCodePresentation
        onClose={unfreeze}
        show={!!attentionWhore.show}
        animationEnd={finishedPresentation}
        input={(input && input[0] && input[0].data) || ""}
        output={(output && output[0] && output[0].data) || ""}
        language={input && input[0] && input[0].programmingLanguage}
      />

      <AttentionWhores
        paused={paused}
        whores={whores}
        clear={flowFinished}
        disabledDrop={!!attentionWhore.show}
      />
    </>
  );
};

export default IOCodeHandler;
