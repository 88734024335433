// interfaces
import { PlayerProps } from "interfaces/player";
import { ChallengeQuizProps } from "interfaces/challengeQuiz";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

// entities
import ChallengeQuizLevelPointsEntity from "entities/ChallengeQuizLevelPointsEntity";

class PlayerChallengeQuizFirebaseEntity {
  private player: PlayerProps;
  private quiz: ChallengeQuizProps;

  constructor({
    quiz,
    player,
  }: {
    player: PlayerProps;
    quiz: ChallengeQuizProps;
  }) {
    this.quiz = quiz;
    this.player = player;
  }

  getSuccessValues(): PlayerFirebaseUpdateProps {
    const { quiz, player } = this;
    const { quizEasy, quizHard, quizMedium } =
      new ChallengeQuizLevelPointsEntity({
        player,
        level: quiz.level,
      }).inc();

    return {
      quiz: {
        easy: quizEasy,
        hard: quizHard,
        medium: quizMedium,
      },
    };
  }

  getFailedValues(): PlayerFirebaseUpdateProps {
    const { quiz, player } = this;
    const { quizEasy, quizHard, quizMedium } =
      new ChallengeQuizLevelPointsEntity({
        player,
        level: quiz.level,
      }).dec();

    return {
      quiz: {
        easy: quizEasy,
        hard: quizHard,
        medium: quizMedium,
      },
    };
  }
}

export default PlayerChallengeQuizFirebaseEntity;
