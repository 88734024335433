interface SvgProps {
  width?: string;
  height?: string;
}

const JarvisSvg = ({ width = "18", height = "18" }: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 60 60"
    >
      <g>
        <path
          fill="#f8cb21"
          d="m57.96 12.687-3.878 2.148a2.543 2.543 0 0 0-1.172 3A24.013 24.013 0 0 1 48.97 39.7 18.435 18.435 0 0 0 45 50.967v2.87A3.119 3.119 0 0 1 42.16 57 3 3 0 0 1 39 54v-3.79a3.221 3.221 0 0 0-1.505-2.789A3 3 0 0 0 33 50v5.84A3.119 3.119 0 0 1 30.16 59 3 3 0 0 1 27 56v-6a3 3 0 0 0-4.459-2.6A3.194 3.194 0 0 0 21 50.187V54a3 3 0 0 1-3.16 3A3.119 3.119 0 0 1 15 53.837v-2.87a18.605 18.605 0 0 0-4.01-11.33 23.867 23.867 0 0 1-4.9-16.7 23.56 23.56 0 0 1 1.006-5.1 2.537 2.537 0 0 0-1.172-3l-3.884-2.15a2.034 2.034 0 0 1-.8-2.74l.47-.89a1.958 1.958 0 0 1 2.69-.809L8.3 10.4a2.539 2.539 0 0 0 3.188-.632 23.962 23.962 0 0 1 37.051-.013 2.536 2.536 0 0 0 3.188.633l3.873-2.14a1.958 1.958 0 0 1 2.69.809l.47.89a2.034 2.034 0 0 1-.8 2.74Z"
          opacity="1"
          data-original="#9693eb"
        ></path>
        <path
          fill="#ffffff"
          d="M45.77 23.96a6.894 6.894 0 0 0 1.208-3.4 7.1 7.1 0 0 0-6.678-7.554A7 7 0 0 0 33 20a6.91 6.91 0 0 0 1.23 3.96 17.939 17.939 0 0 1 11.54 0ZM33 33.79V37a2.006 2.006 0 0 1-2 2h-2a2.006 2.006 0 0 1-2-2v-3.21Z"
          opacity="1"
          data-original="#ffffff"
        ></path>
        <path
          fill="#3778c6"
          d="M34.725 24a5.5 5.5 0 1 1 8.385-1 35.615 35.615 0 0 0-8.11.936Z"
          opacity="1"
          data-original="#3778c6"
        ></path>
        <path
          fill="#ffffff"
          d="M14.23 23.96a6.9 6.9 0 0 1-1.208-3.4 7.1 7.1 0 0 1 6.678-7.554A7 7 0 0 1 27 20a6.91 6.91 0 0 1-1.23 3.96 17.939 17.939 0 0 0-11.54 0Z"
          opacity="1"
          data-original="#ffffff"
        ></path>
        <path
          fill="#3778c6"
          d="M25.275 24a5.5 5.5 0 1 0-8.385-1 35.63 35.63 0 0 1 8.11.936Z"
          opacity="1"
          data-original="#3778c6"
        ></path>
        <path
          fill="#dfb207"
          d="M30 35c-7.449 0-9.738-3.3-9.832-3.445a1 1 0 0 1 1.656-1.121C21.876 30.5 23.787 33 30 33c6.27 0 8.158-2.542 8.177-2.568a1.006 1.006 0 0 1 1.386-.248.992.992 0 0 1 .269 1.371C39.738 31.7 37.449 35 30 35Z"
          opacity="1"
          data-original="#7c7ed0"
        ></path>
        <path
          fill="#2c4359"
          d="M40 20.5a3.45 3.45 0 0 1-1.1 2.53v.01c-.38.02-.74.05-1.09.1-.25.02-.5.06-.73.1a15.5 15.5 0 0 0-2.79.7c-.02.01-.04.01-.06.02a6.815 6.815 0 0 1-1.22-3.71 3.5 3.5 0 0 1 6.99.25ZM20 20.5a3.45 3.45 0 0 0 1.1 2.53v.01c.38.02.74.05 1.09.1.25.02.5.06.73.1a15.5 15.5 0 0 1 2.79.7c.02.01.04.01.06.02a6.815 6.815 0 0 0 1.22-3.71 3.5 3.5 0 0 0-6.99.25Z"
          opacity="1"
          data-original="#2c4359"
        ></path>
        <path
          fill="#dfb207"
          d="M48 26a1 1 0 0 1-.554-.168A14.624 14.624 0 0 0 40 24a14.62 14.62 0 0 0-7.448 1.834 1 1 0 0 1-1.107-1.666A16.325 16.325 0 0 1 40 22a16.325 16.325 0 0 1 8.555 2.168A1 1 0 0 1 48 26Z"
          opacity="1"
          data-original="#7c7ed0"
        ></path>
        <circle
          cx="38.5"
          cy="17.5"
          r="1.5"
          fill="#ffffff"
          opacity="1"
          data-original="#ffffff"
        ></circle>
        <path
          fill="#dfb207"
          d="M12 26a.994.994 0 0 0 .554-.168A14.628 14.628 0 0 1 20 24a14.631 14.631 0 0 1 7.449 1.834 1 1 0 0 0 1.106-1.666A16.321 16.321 0 0 0 20 22a16.315 16.315 0 0 0-8.554 2.168A1 1 0 0 0 12 26Z"
          opacity="1"
          data-original="#7c7ed0"
        ></path>
        <circle
          cx="21.5"
          cy="17.5"
          r="1.5"
          fill="#ffffff"
          opacity="1"
          data-original="#ffffff"
        ></circle>
      </g>
    </svg>
  );
};

export default JarvisSvg;
