// utils
import c from "styles/color";
import styled from "styled-components";

interface ButtonStyledProps {
  bg: string;
  color: string;
  width: string;
  height: string;
  widthFull: boolean;
  boxShadowColor: string;
}

const ButtonStyled = styled.button<ButtonStyledProps>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  letter-spacing: 1.5px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 1.3em;
  font-weight: 500;
  max-width: 310px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bg};
  height: ${({ height }) => (height ? height : "auto")};
  box-shadow: 0 4px 0 ${(props) => props.boxShadowColor};
  width: ${({ width, widthFull }) =>
    widthFull ? "100%" : width ? width : "auto"};

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:disabled {
    opacity: 0.3;
  }
`;

interface ButtonDuolingoStyleProps {
  bg?: string;
  color?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  widthFull?: boolean;
  boxShadowColor?: string;
  type?: "button" | "submit";
}

export type ButtonDuolingoType = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonDuolingoStyleProps;

export interface ButtonDuolingoProps extends ButtonDuolingoType {
  children: React.ReactNode;
}

export interface ButtonDuolingoTemplateProps extends ButtonDuolingoProps {
  outline?: boolean;
}

const ButtonDuolingo = ({
  onClick,
  children,
  disabled,
  className,
  width = "",
  height = "",
  color = c.blue,
  type = "button",
  bg = c.grey.dark,
  widthFull = false,
  boxShadowColor = c.grey.stronger,
}: ButtonDuolingoProps) => {
  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setTimeout(() => onClick && onClick(e), 50);
  }

  return (
    <ButtonStyled
      bg={bg}
      type={type}
      width={width}
      color={color}
      height={height}
      disabled={disabled}
      widthFull={widthFull}
      onClick={handleClick}
      className={className}
      boxShadowColor={boxShadowColor}
    >
      {children}
    </ButtonStyled>
  );
};

export default ButtonDuolingo;
