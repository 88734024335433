// components
import ButtonDuolingo, {
  ButtonDuolingoTemplateProps,
} from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";

const ButtonDuolingoGreen = ({
  type,
  height,
  outline,
  onClick,
  children,
  disabled,
  widthFull,
  className,
}: ButtonDuolingoTemplateProps) => {
  return (
    <ButtonDuolingo
      type={type}
      height={height}
      onClick={onClick}
      disabled={disabled}
      className={className}
      widthFull={widthFull}
      color={outline ? color.green : color.white}
      bg={outline ? color.grey.dark : color.green}
      boxShadowColor={outline ? color.grey.stronger : "#4dbd74"}
    >
      {children}
    </ButtonDuolingo>
  );
};

export default ButtonDuolingoGreen;
