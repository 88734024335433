// firebase
import { UserCredential } from "firebase/auth";

// interfaces
import { UserSignExternalProps } from "interfaces/user";

export default class UserAuthService {
  private username: string = "";
  private userEmail: string = "";
  private userLastName: string = "";
  private userFirstName: string = "";
  private userCredential: UserCredential;

  constructor(credential: UserCredential) {
    this.credential = credential;
    this.userCredential = credential;
  }

  async format(): Promise<UserSignExternalProps> {
    return this.userCredential.user.getIdToken().then((idToken) => {
      return {
        idToken,
        email: this.userEmail,
        lastName: this.lastName,
        firstName: this.firstName,
        givenName: this.displayName,
      };
    });
  }

  set credential(data: UserCredential) {
    if (!data.user || !data.user.displayName) return;

    const displayName = data.user.displayName;
    const [firstName, lastName] = displayName.split(" ");

    this.username = displayName;
    this.userLastName = lastName || "";
    this.userFirstName = firstName || "";
    this.userEmail = data.user.email || "";
  }

  get displayName(): string {
    return this.username;
  }

  get lastName(): string {
    return this.userLastName;
  }

  get firstName(): string {
    return this.userFirstName;
  }
}
