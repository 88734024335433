// react
import { useEffect } from "react";

// redux
import { useDispatch } from "react-redux";
import { classRoomActions } from "redux/slices/classRoomSlice";
import { challengesActions } from "redux/slices/challengesSlice";
import { sectionsBriefActions } from "redux/slices/sectionsBriefSlice";
import { challengeSectionsActions } from "redux/slices/challengeSectionsSlice";
import { programmingLanguagesActions } from "redux/slices/programmingLanguagesSlice";

// parsers
import challengeParser from "parsers/challengeParser";
import classRoomParser from "parsers/classRoomParser";
import sectionsBriefParser from "parsers/sectionsBriefParser";
import challengeQuizParser from "parsers/challengeQuizParser";
import challengeSectionParser from "parsers/challengeSectionParser";
import programmingLanguageParser from "parsers/programmingLanguageParser";

// firebase sync
import { syncPlayerFirebase } from "firebaseSync/PlayerFirebaseSync";
import { syncChallengesFirebase } from "firebaseSync/ChallengesFirebaseSync";

// interfaces
import { AuthProps } from "interfaces/auth";
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { ClassRoomDtoResourceProps } from "interfaces/classRoom";
import { ChallengeFirebaseProps } from "interfaces/challengeFirebase";

// services
import ClassRoomDtoService from "services/ClassRoomDtoService";
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";
import PlayerChallengeFirebaseService from "services/firebase/player/PlayerChallengeFirebaseService";

// utils
import dateUtils from "utils/dateUtils";
import { Timestamp } from "firebase/firestore";

export interface FetchCallbackProps {
  dtoData: ClassRoomDtoResourceProps;
  playerFirebaseData: PlayerFirebaseProps;
  challengesFirebaseData: ChallengeFirebaseProps[];
}

interface FetchClassRoomDataHandlerProps {
  auth: AuthProps;
  languageId: number;
  classRoomId: number;
  successCallback: () => void;
  errorCallback: (error: unknown) => void;
  onStart?: () => void;
}

const FetchClassRoomDataHandler = ({
  auth,
  onStart,
  languageId,
  classRoomId,
  errorCallback,
  successCallback,
}: FetchClassRoomDataHandlerProps) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(start, [auth]);

  function start() {
    if (!onStart) return;
    if (!auth.signed) return;

    onStart();
  }

  useEffect(() => {
    async function handlePromises() {
      try {
        const userId = auth.user.id;
        if (!userId) return;

        const classRoomDtoResource = new ClassRoomDtoService();
        const playerFirebaseResource = new PlayerFirebaseService();
        const challengeFirebaseResource = new PlayerChallengeFirebaseService();
        const [playerFirebase, challengesFirebase]: any = await Promise.all([
          playerFirebaseResource.get(userId),
          challengeFirebaseResource.getCollection(userId, classRoomId),
        ]);

        let checkpoint = getCheckpoint(playerFirebase);

        if (auth.anonymous) checkpoint = 0;
        else if (!isChallengeAvailable(Timestamp.now(), playerFirebase))
          checkpoint -= 1;

        const [dto]: any = await Promise.all([
          classRoomDtoResource.getDaily({
            languageId,
            checkpoint,
            id: classRoomId,
          }),
        ]);

        fetchCallback({
          dtoData: dto.data,
          playerFirebaseData: playerFirebase,
          challengesFirebaseData: challengesFirebase,
        });
      } catch (e: unknown) {
        errorCallback(e);
      }
    }

    handlePromises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, languageId, classRoomId]);

  function fetchCallback({
    dtoData,
    playerFirebaseData,
    challengesFirebaseData,
  }: FetchCallbackProps) {
    try {
      const {
        classRoom: classRoomData,
        challenges: challengesData,
        sections: sectionsData,
        programmingLanguages: programmingLanguagesData,
        challengeQuizzes: challengeQuizzesData,
      } = dtoData;
      const classRoom = classRoomParser.map(classRoomData);
      const sections = challengeSectionParser.list(sectionsData);
      const programmingLanguages = programmingLanguageParser.list(
        programmingLanguagesData
      );
      const challenges = challengeParser.list(
        challengesData,
        programmingLanguagesData
      );

      const quizzes = challengeQuizParser.list(challengeQuizzesData);
      const hashes = [
        challengeParser.hash(
          challengeParser.mix(challenges, challengesFirebaseData)
        ),
      ];
      const sectionsBrief = sectionsBriefParser.map(sections, [
        ...challenges,
        ...quizzes,
      ]);

      dispatch(sectionsBriefActions.set(sectionsBrief));
      dispatch(classRoomActions.set(classRoom));
      dispatch(challengeSectionsActions.set(sections));
      dispatch(programmingLanguagesActions.set(programmingLanguages));
      dispatch(challengesActions.async.merge({ hashes }));

      // sync firebase
      syncPlayerFirebase(playerFirebaseData, dispatch);
      syncChallengesFirebase(challengesFirebaseData, dispatch);

      dispatch(classRoomActions.async.syncFlowParcialDone());
      setTimeout(successCallback);
    } catch (e: unknown) {
      errorCallback(e);
    }
  }

  function getCheckpoint(player: PlayerFirebaseProps) {
    if (!player) return 0;
    return player.checkpoint || 0;
  }

  function isChallengeAvailable(
    current: Timestamp,
    player: PlayerFirebaseProps
  ) {
    if (!player) return true;
    if (!player.last) return true;

    const a = dateUtils.reset(new Date(current.toMillis()));
    const b = dateUtils.reset(new Date(player.last.toMillis()));

    return a.getTime() > b.getTime();
  }

  return null;
};

export default FetchClassRoomDataHandler;
