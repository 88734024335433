// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// enums
import { ChallengeType } from "enums/challengeEnum";

// components
import PressButtonStart from "components/pressButtons/PressButtonStart";
import PressButtonContinue from "components/pressButtons/PressButtonContinue";

// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

interface ChallengeGameFlowHandlerProps {
  paused: boolean;
  challenge: ChallengeCommonProps;
}

const getDescription = (type: ChallengeType, description: string) => {
  switch (type) {
    case ChallengeType.Introduction:
      return <IntroDescription description={description} />;
    case ChallengeType.Exercise:
      return <IntroExercise description={description} />;
    case ChallengeType.Quiz:
      return <IntroQuiz description={description} />;
    default:
      return <IntroDescription description={description} />;
  }
};

const IntroDescription = ({ description }: { description: string }) => (
  <h2 className="description nerdfy_blue animate__animated animate__bounceInRight">
    {description}
  </h2>
);

const IntroExercise = ({ description }: { description: string }) => (
  <h2 className="description nerdfy_orange animate__animated animate__bounceInRight">
    {description}
  </h2>
);

const IntroQuiz = ({ description }: { description: string }) => (
  <h2 className="description nerdfy_green animate__animated animate__bounceInRight">
    {description}
  </h2>
);

const ChallengeGameFlowHandler = ({
  paused,
  challenge,
}: ChallengeGameFlowHandlerProps) => {
  const dispatch = useDispatch();
  const section = useSelector(s.challengeSection());
  const { flowDone, flowInit } = challenge;

  function unpause() {
    if (!paused) return;
    dispatch(challengeFlowActions.unpause());
  }

  return (
    <>
      {paused &&
        !flowDone &&
        (flowInit ? (
          <PressButtonContinue
            onClick={unpause}
            description={getDescription(
              challenge.type,
              `${challenge.name} (${section.name})`
            )}
          />
        ) : (
          <PressButtonStart
            onClick={unpause}
            description={getDescription(
              challenge.type,
              `${challenge.name} (${section.name})`
            )}
          />
        ))}
    </>
  );
};

export default ChallengeGameFlowHandler;
