// react
import { IonContent, IonPage } from "@ionic/react";
import { useState, useEffect, useRef } from "react";

// redux
import { selector as s } from "redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import Sign from "components/sign/Sign";
import Loading from "components/Loading";
import ChallengeStartButton, {
  ChallengeContinueButton,
} from "pages/challenge/ChallengeStartButton";
import Header from "components/header/Header";
import FirebaseSync from "firebaseSync/FirebaseSync";
import HeaderMenu from "components/header/HeaderMenu";
import ExceptionError from "components/ExceptionError";
import ButtonBuy from "components/buttons/ButtonBuy";

// interfaces
import { ChallengeProps } from "interfaces/challenge";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// handlers
import ChallengeHandler from "handlers/ChallengeHandler";
import PlayerStreakHandler from "handlers/PlayerStreakHandler";
import FetchClassRoomDataHandler from "handlers/FetchClassRoomDataHandler";

// utils
import color from "styles/color";
import styled from "styled-components";
import base64Utils from "utils/base64Utils";

/*
  Todo: 

  Prepare:
  
  1) Scenario: Weekends
  
  1.1 Detect if today is weekend;
  1.2 Ask to users come back on Monday; 

  2) Scenario: Demo

  2.1 Detect if user is demo;
  2.2 Ask to users sign up;
*/

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .buy_button {
    margin-right: 30px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  --ion-background-color: ${color.dark.bg};

  .button_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const ChallengePage = () => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const payment = useSelector(s.payment());
  const classRoom = useSelector(s.classRoom());
  const challenge = useSelector(s.challenge());
  const sections = useSelector(s.challengeSections());
  const challengesHash = useSelector(s.challengesHash());
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isChallengeModalOpen, setIsChallengeModalOpen] = useState(false);
  const sectionsRef = useRef<ChallengeSectionProps[]>([]);
  const challengesHashRef = useRef<any>();
  const alreadyOpenAutomatically = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSections, [sections]);
  useEffect(handleChallengesHash, [challengesHash]);
  useEffect(listenToCloseChallengeModal, [isChallengeModalOpen]);

  function listenToCloseChallengeModal() {
    if (isChallengeModalOpen) return;
    alreadyOpenAutomatically.current = false;
  }

  function handleSections() {
    sectionsRef.current = sections;
  }

  function handleChallengesHash() {
    challengesHashRef.current = challengesHash;
  }

  function openModal() {
    openChallengeModal();
  }

  function openChallengeModal() {
    if (!auth.signed) return;
    if (isChallengeModalOpen) return;

    const section = getSection();
    const challenge = getChallenge(section);

    if (!section) return;
    if (!challenge) return;

    setTimeout(() => {
      dispatch(challengeActions.async.openModal(challenge, section));
      setTimeout(() => setIsChallengeModalOpen(true));
    });
  }

  function getSection(): ChallengeSectionProps {
    return sectionsRef.current[0];
  }

  function getChallenge(section: ChallengeSectionProps): ChallengeProps {
    const challengesFromSection = (challengesHashRef.current[section.id] ||
      []) as ChallengeProps[];
    return challengesFromSection[0] || [];
  }

  function onOrderModalDismissed() {
    setIsSignInModalOpen(false);
  }

  function onOrderModalFinished() {
    setIsSignInModalOpen(false);
    openChallengeModalAutomatically(false);
  }

  function onStart() {
    setLoading(true);
  }

  function errorCallback(_error: unknown) {
    setLoading(false);
    setHasError(true);
  }

  function successCallback() {
    setTimeout(() => setLoading(false), 350);
    openChallengeModalAutomatically(isSignInModalOpen);
  }

  function openChallengeModalAutomatically(isSignInModalOpen: boolean) {
    if (loading) return;
    if (isSignInModalOpen) return;
    if (alreadyOpenAutomatically.current) return;

    setTimeout(openChallengeModal, 250);
    alreadyOpenAutomatically.current = true;
  }

  function openSignInModal() {
    setIsSignInModalOpen(true);
  }

  function goProp() {
    window.location.href = `${
      process.env.REACT_APP_LANDPAGE_DOMAIN
    }/unlock?c=${base64Utils.encode(payment.url)}`;
  }

  return (
    <Wrapper>
      <HeaderMenu signed={auth.signed} />

      <IonPage id="mainContent">
        <Header
          button={
            auth.signed && !auth.anonymous && !auth.user.paid ? (
              <ButtonBuy onClick={goProp} />
            ) : undefined
          }
        />
        <Loading loading={loading} />

        <IonContent>
          <Content>
            <div className="button_wrapper">
              {hasError ? (
                <ExceptionError />
              ) : auth.signed ? (
                <ChallengeContinueButton
                  loading={loading}
                  onClick={openModal}
                  challenge={challenge}
                />
              ) : (
                <ChallengeStartButton
                  loading={loading}
                  onClick={openSignInModal}
                />
              )}
            </div>

            {auth.signed && (
              <FetchClassRoomDataHandler
                auth={auth}
                languageId={1}
                classRoomId={1}
                onStart={onStart}
                errorCallback={errorCallback}
                successCallback={successCallback}
              />
            )}

            {!!(auth.firebaseSigned && auth.user && classRoom.id) && (
              <FirebaseSync
                user={auth.user}
                classRoomId={classRoom.id}
                languageId={classRoom.languageId}
              />
            )}

            <Sign
              isOpen={isSignInModalOpen}
              close={onOrderModalDismissed}
              finished={onOrderModalFinished}
            />

            <ChallengeHandler
              sections={sections}
              isChallengeQuizModalOpen={false}
              openChallengeQuizModal={() => {}}
              challengesMerged={challengesHash}
              toggleChallengeQuizModal={() => {}}
              openChallengeModal={openChallengeModal}
              isChallengeModalOpen={isChallengeModalOpen}
              toggleChallengeModal={setIsChallengeModalOpen}
            />

            <PlayerStreakHandler />
          </Content>
        </IonContent>
      </IonPage>
    </Wrapper>
  );
};

export default ChallengePage;
