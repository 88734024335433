import { PlayerProps } from "interfaces/player";
import { PlayerFirebaseProps } from "interfaces/playerFirebase";

function map(player: PlayerFirebaseProps): PlayerProps {
  return {
    // stats
    help: player.help || 0,
    missed: player.missed || 0,
    killed: player.killed || 0,
    streak: player.streak || 0,
    last: getPlayerLastMili(player),
    maxStreak: player.maxStreak || 0,
    checkpoint: player.checkpoint || 0,

    // quiz points
    quiz: {
      easy: player.quiz?.easy || 0,
      medium: player.quiz?.medium || 0,
      hard: player.quiz?.hard || 0,
    },

    // rating
    rating: {
      message: player.rating?.message || "",
      value: player.rating?.value || 0,
    },
  };
}

function getPlayerLastMili(player: PlayerFirebaseProps) {
  if (!player) return 0;
  if (!player.last) return 0;

  return player.last.toMillis();
}

const playerParser = {
  map,
};

export default playerParser;
