// react
import React from "react";

// enums
import { ChallengeType } from "enums/challengeEnum";

// svgs
import AvatarIconNerdPlaceholder from "components/avatarIcons/placeholders/AvatarIconNerdPlaceholder";
import AvatarIconErgoPlaceholderIcon from "components/avatarIcons/placeholders/AvatarIconErgoPlaceholder";

// remover

// utils
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
`;

interface AvatarIconProps {
  type: ChallengeType;
  avatarColor?: string;
  avatarWidth?: string;
  avatarHeight?: string;
  avatarInvader?: number;
  avatarGradient?: boolean;
  customClassName?: string;
}

const AvatarIconHandler = React.memo(
  ({
    type,
    avatarColor,
    avatarInvader,
    avatarGradient,
    avatarWidth = "28",
    avatarHeight = "28",
    customClassName = "",
  }: AvatarIconProps) => {
    return (
      <>
        {type === ChallengeType.Introduction && (
          <Wrapper className={customClassName}>
            <AvatarIconNerdPlaceholder
              width={avatarWidth}
              height={avatarHeight}
            />
          </Wrapper>
        )}

        {type === ChallengeType.Quiz && (
          <Wrapper className={customClassName}>
            <AvatarIconErgoPlaceholderIcon
              color={avatarColor}
              width={avatarWidth}
              height={avatarHeight}
              gradient={avatarGradient}
            />
          </Wrapper>
        )}
      </>
    );
  }
);

export default React.memo(AvatarIconHandler);
