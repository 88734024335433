// interfaces
import { ChallengeCommonProps } from "interfaces/challenge";

// components
import ButtonDuolingo from "components/buttons/ButtonDuolingo";

// utils
import styled from "styled-components";

const ButtonDuolingoWrapper = styled(ButtonDuolingo)`
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 20px 25px;
  font-size: 1.5em !important;
`;

export interface ButtonAccordionProps {
  onClick(): void;
  challenge: ChallengeCommonProps;
  disabled?: boolean;
}

interface ButtonAccordionLabelProps {
  label: string;
  customClassName?: string;
}

export function ButtonAccordionLabel({
  label,
  customClassName,
}: ButtonAccordionLabelProps) {
  return <span className={`${customClassName}`}>{label}</span>;
}

interface ButtonProps {
  onClick(): void;
  children: React.ReactNode;
  disabled?: boolean;
}

const ButtonAccordion = ({ onClick, children, disabled }: ButtonProps) => {
  return (
    <ButtonDuolingoWrapper onClick={onClick} disabled={disabled}>
      {children}
    </ButtonDuolingoWrapper>
  );
};

export default ButtonAccordion;
