// components
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";
import styled from "styled-components";
import color from "styles/color";

interface ButtonDuolingoBuyProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
}

const Wrapper = styled(ButtonRippleEffect)`
  margin-right: 30px;
  padding: 8px 15px;
  border: 2px solid ${color.grey.stronger};

  &:hover {
    border: 2px solid ${color.green};
  }

  &:active {
    border: 2px solid ${color.green};
  }
`;

const ButtonBuy = ({ onClick, className }: ButtonDuolingoBuyProps) => {
  return (
    <Wrapper onClick={onClick} color={color.green} className={className}>
      Unlock
    </Wrapper>
  );
};

export default ButtonBuy;
