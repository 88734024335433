// components
import Code from "components/Code";
import { size } from "lodash";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
  height: 100%;
`;

interface ChallengeCodeProps {
  code: string;
  language: string;
  wrap?: boolean;
  fontSize?: string;
}

const ChallengeCode = ({
  code,
  language,
  wrap = false,
  fontSize = "1em",
}: ChallengeCodeProps) => {
  return (
    <Wrapper>
      <Code
        wrap={wrap}
        code={code}
        tomorrowStyle
        language={language}
        showLineNumbers={false}
        style={{ height: "100%", margin: 0, fontSize }}
      />
    </Wrapper>
  );
};

export default ChallengeCode;
