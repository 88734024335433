// react
import { useEffect, useRef, useState } from "react";

// redux
import { useDispatch } from "react-redux";
import { challengeActions } from "redux/slices/challengeSlice";

// components
import Confetti from "components/Celebration";
import ProgressStatus from "components/ProgressStatus";
import FeedbackHandler from "handlers/feedback/FeedbackHandler";
import ChallengeCode from "components/challenge/battle/ChallengeCode";
import ChallengeOptionsFooter from "components/ChallengeOptionsFooter";
import ChallengeStatusBar from "components/challenge/ChallengeStatusBar";
import { DropZoneTag, DropZoneAttentionWhore } from "components/DropZone";
import ChallengeTags from "components/challenge/battle/tags/ChallengeTags";

// factories
import {
  alertAnimate,
  AlertFactory,
  AlertFactoryType,
} from "factories/AlertFactory";

// handlers
import BittlesHandler from "handlers/BittlesHandler";
import ChallengeGameFlowHandler from "handlers/ChallengeGameFlowHandler";
import IOCodeHandler from "handlers/challengeButtons/ioCode/IOCodeHandler";
import ChallengeBattleProgressHandler from "handlers/ChallengeBattleProgressHandler";

// svgs
import DoneSvg from "svg/DoneSvg";
import CrossSvg from "svg/CrossSvg";

// interfaces
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// utils
import color from "styles/color";
import styled from "styled-components";
import { TagProps } from "interfaces/tag";
import numberUtils from "utils/numberUtils";
import { tagActions } from "redux/slices/tagSlice";
import tagGuesserUtils from "utils/code/tagGuesserUtils";
import { ChallengeCodeStepProps } from "interfaces/challengeCode";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .challenge_battle_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .code_wrapper {
    flex: 1;
    height: 100%;
  }

  .result_header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    background: ${color.grey.dark};
  }
`;

interface TagBoundsProps {
  left: string;
  top: string;
}

interface ChallengeBattleProps extends ChallengeTypeHandlerCommonProps {
  onFailedTag(bounds: TagBoundsProps): void;
}

const TOTAL_LIFE = 3;

const ChallengeBattle = ({
  code,
  paused,
  challenge,
  wrapperRef,
  isTagValid,
  onFailedTag,
  onSuccessTag,
  handlerWrapperRef,
  handlerNotifiersRef,
}: ChallengeBattleProps) => {
  const dispatch = useDispatch();
  const disabledTagDropRef = useRef(false);
  const [missedProgress, setMissedProgress] = useState(1);
  const poisonHpRemainingRef = useRef(getBiggestStepLength());
  const remainingHpRef = useRef(TOTAL_LIFE - challenge.missed);

  const {
    tags,
    missed,
    flowInit,
    flowDone,
    flowStarted,
    flowSuccess,
    currentCode,
    flowFinished,
    specialTags,
    programmingLanguageType,
  } = challenge;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleMissedProgress, [missed, flowDone]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleInitFlow, [paused, flowInit]);

  function handleMissedProgress() {
    if (!missed) return;

    const progress = (TOTAL_LIFE - missed) / TOTAL_LIFE;
    setMissedProgress(progress);

    if (progress <= 0 && !flowDone)
      setTimeout(() => dispatch(challengeActions.async.failed()));
  }

  function handleInitFlow() {
    if (paused) return;
    if (flowInit) return;

    setTimeout(() => dispatch(challengeActions.async.init()));
  }

  function help() {
    if (flowDone) return;
    if (flowInit && !flowStarted) return;

    const tag = tagGuesserUtils.get(tags, specialTags, code);
    if (!tag) return;

    add({ ...tag, fromAvatar: true });
    dispatch(tagActions.async.help());
  }

  function add(tag: TagProps) {
    if (isTagValid(tag)) {
      alertHealPoison();
      onSuccessTag(tag);

      return;
    }
  }

  function onAddedTag(tag: TagProps) {
    alertHealPoison();
    onSuccessTag(tag);
  }

  function onWrongTag(bounds: TagBoundsProps) {
    alertLostHp();
    onFailedTag(bounds);
  }

  function alertHealPoison() {
    poisonHpRemainingRef.current -= 1;

    alert(
      `-1 poisoned [${poisonHpRemainingRef.current}]`,
      AlertFactoryType.Warning
    );
  }

  function alertLostHp() {
    remainingHpRef.current -= 1;
    alert(`-1 hp [${remainingHpRef.current}]`, AlertFactoryType.Danger);
  }

  function alert(content: string, type: AlertFactoryType) {
    if (!wrapperRef.current) return;

    alertAnimate({
      factories: [
        AlertFactory({
          type,
          content,
        }),
      ],
      wrapper: wrapperRef.current as HTMLDivElement,
      bounds: {
        top: `${numberUtils.randomInterval(
          100,
          wrapperRef.current.clientHeight - 200
        )}px`,
        left: `${numberUtils.randomInterval(
          100,
          wrapperRef.current.clientWidth - 200
        )}px`,
      },
    });
  }

  function getBiggestStepLength(): number {
    if (!code) return 0;
    if (!code.steps) return 0;

    return code.steps.reduce((acc: number, list: ChallengeCodeStepProps) => {
      const { length } = list.data;

      if (acc < length) acc = length;
      return acc;
    }, 0);
  }

  return (
    <>
      <ChallengeBattleProgressHandler
        challenge={challenge}
        getBiggestStepLength={getBiggestStepLength}
      />

      <Wrapper
        ref={handlerWrapperRef}
        className={`challenge_battle_wrapper ${
          !paused && !flowDone && "no-swipe"
        }`}
      >
        <div className="nerdfy_notifiers" ref={handlerNotifiersRef}></div>

        {flowDone && (
          <div
            className={
              flowFinished
                ? "animate__animated animate__bounceInRight result_header"
                : "result_header"
            }
          >
            {flowSuccess ? <DoneSvg /> : <CrossSvg />}
          </div>
        )}

        <ChallengeStatusBar />

        <DropZoneTag bottom="10px" />
        <DropZoneAttentionWhore bottom="10px" />

        {flowStarted && (
          <ChallengeTags
            code={code}
            tags={tags}
            paused={paused}
            clear={flowDone}
            isTagValid={isTagValid}
            onFailedTag={onWrongTag}
            onSuccessTag={onAddedTag}
            wrapper={wrapperRef.current}
            disabledDropRef={disabledTagDropRef}
          />
        )}

        <div className="challenge_battle_content">
          <div className="code_wrapper">
            <ChallengeCode
              wrap
              code={currentCode}
              language={programmingLanguageType}
            />
          </div>

          <ChallengeOptionsFooter
            wrapper={wrapperRef.current}
            disabled={challenge.flowDone}
          />

          {/* **** GAME FLOW **** */}

          <ChallengeGameFlowHandler paused={paused} challenge={challenge} />

          {/* GAME FLOW END */}
        </div>

        {/* **** HANDLERS **** */}

        {flowDone && flowFinished && flowSuccess && <Confetti />}

        {flowInit && (
          <IOCodeHandler
            paused={paused}
            challenge={challenge}
            wrapper={wrapperRef.current}
          />
        )}

        <FeedbackHandler challenge={challenge} />

        <BittlesHandler
          onClick={help}
          paused={paused}
          show={!paused && !flowDone}
          success={flowFinished && flowSuccess}
        />

        {/* HANDLERS END */}
      </Wrapper>
      <ProgressStatus color={color.red} value={missedProgress} />
    </>
  );
};

export default ChallengeBattle;
