// redux
import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice, createAction } from "@reduxjs/toolkit";

// interfaces
import { ClassRoomProps } from "interfaces/classRoom";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

const syncFlowParcialDone = createAction(
  "@async/classRoom/syncFlowParcialDone"
);

export const initialState: ClassRoomProps = {
  id: 0,
  languageId: 0,
  name: "",
  content: [],
  requirements: [],
  description: "",
  image: "",
  language: "",
  languageAlias: "",
  level: "",
  createdAt: "",
  updatedAt: "",
  creator: "",
  creatorLastName: "",
  creatorImage: "",
  creatorDescription: "",

  // flows
  flowParcialDone: false,
};

export const classRoomSlice = createSlice({
  name: "classRoom",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<ClassRoomProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<AllToOptional<ClassRoomProps>>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
});

export const classRoomActions = {
  async: {
    syncFlowParcialDone,
  },
  ...classRoomSlice.actions,
};
export default classRoomSlice.reducer;
