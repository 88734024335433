import { ApplicationErrorProps } from "interfaces/error";
import { ERROR_TYPES } from "constants/error";
import AuthError from "errors/AuthError";
import UnknownError from "errors/UnknownError";
import ApplicationError from "errors/ApplicationError";

const handle = {
  auth(e: unknown): Error {
    const error = e as ApplicationErrorProps;
    let customError = "Algum erro aconteceu...";

    if (!error) return new UnknownError();

    const { data } = error;
    if (!data || !data.type) return new UnknownError();

    const { type } = data;

    if (data && data.type === ERROR_TYPES.USER_NOT_FOUND)
      customError = "Email or password incorrect";
    else if (type === ERROR_TYPES.USER_ALREADY_REGISTERED) {
      customError = "Email already registered";
    } else if (type === ERROR_TYPES.USER_NOT_FOUND) {
      customError = "User not found";
    }

    return new AuthError(customError);
  },
  general(e: unknown): Error {
    const error = e as ApplicationErrorProps;
    let customError = "Ops something went wrong...";

    if (!error) return new UnknownError();
    const { data } = error;

    console.log("data :>> ", data);
    if (!data || !data.type) return new UnknownError();

    const { type } = data;

    if (type === ERROR_TYPES.USER_NOT_FOUND) {
      customError = "User not found";
    } else if (type === ERROR_TYPES.EMAIL_ALREADY_REGISTERED) {
      customError = "Email already registered";
    }

    return new ApplicationError(data.message || customError);
  },
};

const errorUtils = {
  handle,
};

export default errorUtils;
