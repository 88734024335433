// react
import { useRef, useState } from "react";
import { IonicSlides } from "@ionic/react";

// swiper
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// components
import JarvisSpeech from "./JarvisSpeech";
import End from "components/customize/End";
import JarvisNaming from "components/customize/JarvisNaming";
import PlayerNaming from "components/customize/PlayerNaming";
import Introduction, { Blank } from "components/customize/Introduction";

// utils
import color from "styles/color";
import styled from "styled-components";
import ProgressBar from "components/ProgressBar";
import { AuthProps } from "interfaces/auth";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  .slide {
    flex: 1;
    display: flex;

    .user_input {
      padding: 10px;
      color: ${color.grey.light};
      background: ${color.grey.dark};
    }
  }
`;

const SLIDE_INTRO = 0;
const SLIDE_PLAYER_NAME = 1;
const SLIDE_JARVIS_NAME = 2;
const SLIDE_INTRO_2 = 3;
const SLIDE_END = 4;
const SLIDE_BACK_END = 5;

interface CustomizeProps {
  show: boolean;
  finished(): void;
  auth: AuthProps;
}

const Customize = ({ auth, finished, show }: CustomizeProps) => {
  const swiperRef = useRef<SwiperProps | undefined>();
  const [username, setUsername] = useState("");
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(SLIDE_INTRO);
  const [jarvisName, setJarvisName] = useState("");
  const [disabledBack, setDisabledBack] = useState(true);
  const [disabled, setDisabled] = useState({
    [SLIDE_END]: true,
    [SLIDE_INTRO]: true,
    [SLIDE_INTRO_2]: true,
    [SLIDE_PLAYER_NAME]: true,
    [SLIDE_JARVIS_NAME]: true,
  });

  function onSubmitUserName(name: string) {
    setUsername(name);
  }

  function onSubmitJarvisName(name: string) {
    setJarvisName(name);
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  function goToIntroduction() {
    setProgress(0);
    setTimeout(() => slideTo(SLIDE_INTRO));
  }

  function goToPlayerNameSlide() {
    setProgress(0.25);
    updateStep(SLIDE_PLAYER_NAME);

    setTimeout(() => slideTo(SLIDE_PLAYER_NAME));
  }

  function goToJarvisNameSlide() {
    setProgress(0.5);
    updateStep(SLIDE_JARVIS_NAME);

    setTimeout(() => slideTo(SLIDE_JARVIS_NAME));
  }

  function goToIntroTwoSlide() {
    setProgress(0.75);
    updateStep(SLIDE_INTRO_2);

    setTimeout(() => slideTo(SLIDE_INTRO_2));
  }

  function goToEndSlide() {
    setProgress(1);
    updateStep(SLIDE_END);

    setTimeout(() => slideTo(SLIDE_END));
  }

  function goBackToPreviousEndSlide() {
    setStep(SLIDE_BACK_END);
    goToIntroTwoSlide();
  }

  function onFinishedLastSpeech() {
    onFinishedSpeech();
    setDisabledBack(false);
  }

  function onFinishedSpeech() {
    setDisabled({ ...disabled, [step]: false });
  }

  function updateStep(s: number) {
    if (s <= step) return;
    setStep(s);
  }

  return (
    <>
      {show && (
        <Wrapper className={`animate__animated animate__bounceInUp`}>
          <ProgressBar value={progress} />

          {step === SLIDE_INTRO && (
            <JarvisSpeech
              delay={1800}
              onFinished={onFinishedSpeech}
              content={[
                "Welcome! I'll be here to guide you every step of the way on your coding journey.",
                "Don't worry, no boring lectures here!",
                "We're going to progress faster, our approach is six times more effective than watching lecture videos.",
              ]}
            />
          )}

          {step === SLIDE_PLAYER_NAME && (
            <JarvisSpeech
              onFinished={onFinishedSpeech}
              content={[
                "Before we begin, can we get to know each other? What shall I call you?",
              ]}
            />
          )}

          {step === SLIDE_JARVIS_NAME && (
            <JarvisSpeech
              onFinished={onFinishedSpeech}
              content={[
                `Alright, ${username}, now it's my turn. My name is...`,
              ]}
            />
          )}

          {step === SLIDE_INTRO_2 && (
            <JarvisSpeech
              onFinished={onFinishedSpeech}
              content={[
                "Bit by bit, daily learning makes a real difference.",
                "We'll keep you on track with challenges, quizzes, and daily motivation.",
                "Sounds good?",
              ]}
            />
          )}

          {step === SLIDE_END && (
            <JarvisSpeech
              onFinished={onFinishedLastSpeech}
              content={[
                "Btw, keep an eye on the bittles, they look cute, but they’re very mischievous creatures!",
                "We have a long journey ahead with 262 weekday challenges.",
                "Are you ready?",
              ]}
            />
          )}

          {step === SLIDE_BACK_END && (
            <JarvisSpeech
              onFinished={onFinishedLastSpeech}
              content={["Are you up for it??"]}
            />
          )}

          <Swiper
            initialSlide={0}
            slidesPerView={1}
            onSwiper={handleSwiper}
            modules={[IonicSlides]}
            noSwipingClass="no_swipe"
          >
            <SwiperSlide>
              <div className="slide no_swipe">
                <Introduction
                  goForward={goToPlayerNameSlide}
                  proceedDisabled={disabled[SLIDE_INTRO]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <PlayerNaming
                  goBack={goToIntroduction}
                  disabledBack={disabledBack}
                  onSubmit={onSubmitUserName}
                  username={auth?.user.firstName}
                  goForward={goToJarvisNameSlide}
                  proceedDisabled={disabled[SLIDE_PLAYER_NAME]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <JarvisNaming
                  disabledBack={disabledBack}
                  goBack={goToPlayerNameSlide}
                  goForward={goToIntroTwoSlide}
                  onSubmit={onSubmitJarvisName}
                  proceedDisabled={disabled[SLIDE_JARVIS_NAME]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <Blank
                  goForward={goToEndSlide}
                  disabledBack={disabledBack}
                  goBack={goToJarvisNameSlide}
                  proceedDisabled={disabled[SLIDE_INTRO_2]}
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="slide no_swipe">
                <End
                  auth={auth}
                  finished={finished}
                  username={username}
                  jarvisName={jarvisName}
                  disabledBack={disabledBack}
                  goBack={goBackToPreviousEndSlide}
                  proceedDisabled={disabled[SLIDE_END]}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </Wrapper>
      )}
    </>
  );
};

export default Customize;
