// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

const IconWhoreInfo = ({ active }: AttentionWhoreElProps) => {
  return (
    <span
      className={`jarvis_alert jarvis_alert_big pixelify_font_family ${
        active ? "nerdfy_blue" : "nerdfy_grey_stronger"
      }`}
    >
      !
    </span>
  );
};

export default IconWhoreInfo;
