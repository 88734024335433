import { Howl } from "howler";

const TINY_SOUND_PATCH = "assets/audio/hit.wav";
const MISSED_SOUND_PATCH = "assets/audio/missed.wav";
// const BUBBLE_SOUND_PATCH = "assets/audio/bubble.wav";

interface PreloadProps {
  hit: undefined | Howl;
  missed: undefined | Howl;
  bubble: undefined | Howl;
}

interface PlayProps {
  hit(): void;
  missed(): void;
  bubble(): void;
}

let preloaded = false;

const _handler: PreloadProps = {
  hit: undefined,
  missed: undefined,
  bubble: undefined,
};

function bootstrap() {
  if (preloaded) return;
  preloaded = true;

  _handler.hit = new Howl({
    src: [TINY_SOUND_PATCH],
    autoplay: false,
    volume: 0.5,
  });

  _handler.missed = new Howl({
    src: [MISSED_SOUND_PATCH],
    autoplay: false,
    volume: 0.5,
  });

  _handler.bubble = new Howl({
    src: [TINY_SOUND_PATCH],
    autoplay: false,
    volume: 0.5,
  });
}

const play: PlayProps = {
  hit() {
    _handler.hit?.play();
  },
  missed() {
    _handler.missed?.play();
  },
  bubble() {
    _handler.bubble?.play();
  },
};

const audioUtils = {
  bootstrap,
  play,
};

export default audioUtils;
