// components
import PressButton from "components/pressButtons/PressButton";

// utils
import color from "styles/color";

interface PressButtonProps {
  onClick: () => void;
  description: JSX.Element;
}

const PressButtonStart = ({ onClick, description }: PressButtonProps) => {
  return (
    <PressButton
      onClick={onClick}
      color={color.green}
      title="Press to Start"
      description={description}
    />
  );
};

export default PressButtonStart;
