// formik
import { Formik, Form, FormikProps } from "formik";

// components
import FormHeader from "components/form/FormHeader";
import FormikInput from "components/formik/FormikInput";
import FormikAutoFill from "components/formik/FormikAutoFill";

// utils
import * as yup from "yup";
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;

    form {
      flex: 1;
      display: flex;
      flex-direction: column;

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;

interface PlayerNameProps {
  name: string;
}

interface PlayerNamingProps {
  goBack(): void;
  goForward(): void;
  disabledBack: boolean;
  proceedDisabled: boolean;
  username: string | undefined;
  onSubmit(name: string): void;
}

const PlayerNaming = ({
  goBack,
  onSubmit,
  goForward,
  disabledBack,
  username = "",
  proceedDisabled,
}: PlayerNamingProps) => {
  function getSchemaValidation() {
    const name = yup
      .string()
      .required("What is your name?")
      .matches(
        /^[a-zA-ZÀ-ÿ\s]{2,30}$/,
        "Name must be 2-30 characters long and contain only letters and spaces"
      );

    return yup.object({
      name,
    });
  }

  function getInitialValues(): PlayerNameProps {
    return {
      name: username,
    };
  }

  function go(submit: () => void) {
    submit();
  }

  function submit(data: PlayerNameProps) {
    onSubmit(data.name);
    setTimeout(goForward);
  }

  return (
    <Wrapper>
      <Formik
        onSubmit={submit}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getInitialValues()}
        validationSchema={getSchemaValidation()}
      >
        {(props: FormikProps<PlayerNameProps>) => {
          return (
            <div className="body">
              <Form noValidate>
                <FormikAutoFill
                  field="name"
                  props={props}
                  identifier="username"
                />

                <FormHeader
                  back={goBack}
                  disabled={proceedDisabled}
                  disabledBack={disabledBack}
                  proceed={go.bind(null, props.submitForm)}
                />

                <div className="content"></div>

                <div className="user_input">
                  <FormikInput
                    name="name"
                    id="username"
                    autocorrect="off"
                    label="Type your name"
                    bgColor={color.grey.dark}
                    placeholder="Type your name"
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default PlayerNaming;
