// redux
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { nextChallengeActions } from "redux/slices/nextChallengeSlice";

// interfaces
import {
  ChallengeCommonProps,
  ChallengesCommonHashProps,
} from "interfaces/challenge";
import { ChallengeSectionProps } from "interfaces/challengeSection";

// entities
import ChallengeQuizAvailabilityEntity from "entities/ChallengeQuizAvailabilityEntity";

// enums
import { ChallengeType } from "enums/challengeEnum";
import { NextChallengeDisabledType } from "enums/nextChallengeEnum";

// utils
import stateUtils, { listenerApiType } from "redux/utils/stateUtils";

const challengeMiddleware = createListenerMiddleware();

// nextChallenge/add
challengeMiddleware.startListening({
  actionCreator: nextChallengeActions.async.set,
  effect: async ({ payload }, listenerApi) => {
    const { challenge, challenges } = payload;
    const { challengeSections: sections } = stateUtils.get(listenerApi);

    syncNextChallenge({
      sections,
      challenge,
      challenges,
      listenerApi,
    });
  },
});

// private
interface SyncNextChallengeProps {
  listenerApi: listenerApiType;
  challenge: ChallengeCommonProps;
  sections: ChallengeSectionProps[];
  challenges: ChallengesCommonHashProps;
}

function syncNextChallenge({
  sections,
  challenge,
  challenges,
  listenerApi,
}: SyncNextChallengeProps) {
  let nextChallenge: ChallengeCommonProps | undefined;
  let challengesFromSection: ChallengeCommonProps[] | undefined;

  setTimeout(() => {
    if (challenge.index === undefined) return disableNextChallenge(listenerApi);

    const currentSection = sections.find(
      (section) => section.id === challenge.challengeSectionId
    );

    if (!currentSection) return disableNextChallenge(listenerApi);

    challengesFromSection = challenges[currentSection.id];

    if (!challengesFromSection) return disableNextChallenge(listenerApi);

    const currentChallengeIndex = challengesFromSection.findIndex(
      (c) => c.uuid === challenge.uuid
    );

    if (currentChallengeIndex === -1) return disableNextChallenge(listenerApi);

    nextChallenge = challengesFromSection[currentChallengeIndex + 1];

    if (nextChallenge) {
      if (!nextChallenge.free) return disableNextChallenge(listenerApi);

      const isDisabledByQuiz = isNextChallengeDisabledByQuiz(
        nextChallenge,
        challengesFromSection
      );

      listenerApi.dispatch(
        nextChallengeActions.set({
          section: currentSection,
          challenge: nextChallenge,
          disabled: isDisabledByQuiz,
          disabledType: isDisabledByQuiz
            ? NextChallengeDisabledType.Quiz
            : NextChallengeDisabledType.None,
        })
      );

      return;
    }

    // trying challenge from next section
    const currentSectionIndex = sections.findIndex(
      (section) => section.id === currentSection.id
    );

    if (currentSectionIndex === -1) return disableNextChallenge(listenerApi);

    const nextSection = sections[currentSectionIndex + 1];
    if (!nextSection) return disableNextChallenge(listenerApi);

    challengesFromSection = challenges[nextSection.id];
    if (!challengesFromSection) return disableNextChallenge(listenerApi);

    nextChallenge = challengesFromSection[0];

    if (!nextChallenge) return disableNextChallenge(listenerApi);
    if (!nextChallenge.free) return disableNextChallenge(listenerApi);

    listenerApi.dispatch(
      nextChallengeActions.set({
        disabled: false,
        section: nextSection,
        challenge: nextChallenge,
        disabledType: NextChallengeDisabledType.None,
      })
    );
  });
}

function isNextChallengeDisabledByQuiz(
  nextChallenge: ChallengeCommonProps,
  challengesFromSection: ChallengeCommonProps[]
) {
  if (nextChallenge.type !== ChallengeType.Quiz) return false;

  return !new ChallengeQuizAvailabilityEntity({
    quiz: nextChallenge,
    challenges: challengesFromSection,
  }).is();
}

function disableNextChallenge(listenerApi: listenerApiType) {
  listenerApi.dispatch(nextChallengeActions.clear());
}

export default challengeMiddleware;
