import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import styled from "styled-components";
import color from "styles/color";
import {
  tomorrow,
  prism,
} from "react-syntax-highlighter/dist/esm/styles/prism";

const Wrapper = styled(SyntaxHighlighter)`
  height: 100% !important;
  background: ${color.dark.bg} !important;
`;

type Props = {
  language: string;
  code: string;
  wrap?: boolean;
  style?: any;
  tomorrowStyle?: boolean;
  showLineNumbers?: boolean;
};

const Code: React.FC<Props> = ({
  language,
  code,
  style,
  tomorrowStyle,
  wrap = false,
  showLineNumbers = true,
}) => {
  return (
    <Wrapper
      wrapLongLines={wrap}
      language={language}
      style={tomorrowStyle ? tomorrow : prism}
      customStyle={style}
      children={code || ""}
      showLineNumbers={showLineNumbers}
      showInlineLineNumbers={false}
      lineNumberStyle={{ opacity: 0.3 }}
      codeTagProps={{
        style: {
          fontSize: "1.1em",
          fontFamily: "Fira Mono",
          height: "100%",
        },
      }}
    />
  );
};

function areEqual(prev: any, next: any) {
  return prev && prev.code === next && next.code;
}

export default React.memo(Code, areEqual);
