// ionic
import { IonButton } from "@ionic/react";

// utils
import styled from "styled-components";

const LinkButtonStyled = styled(IonButton)`
  text-decoration: none;
  text-transform: none;
  background: none;
  height: 50px;
  width: 100%;
  font-size: 1em;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
  }
`;

interface LinkButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const LinkButton = ({ children, onClick }: LinkButtonProps) => {
  return (
    <LinkButtonStyled onClick={onClick} fill="clear" color="secondary">
      {children}
    </LinkButtonStyled>
  );
};

export default LinkButton;
