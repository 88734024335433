// redux
import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { appActions } from "redux/slices/appSlice";

// interfaces
import { CheckpointsStateProps } from "interfaces/checkpoints";

const initialState: CheckpointsStateProps = {};

export const checkpointsSlice = createSlice({
  name: "checkpoints",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<CheckpointsStateProps>) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const checkpointsActions = checkpointsSlice.actions;
export default checkpointsSlice.reducer;
