// react
import { useEffect, useRef, useState } from "react";

// firebase
import { increment } from "firebase/firestore";

// redux
import { selector as s } from "redux/selectors";
import { authActions } from "redux/slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { paymentActions } from "redux/slices/paymentSlice";

// ionic-react
import { IonRippleEffect } from "@ionic/react";

// components
import Modal from "components/Modal";
import Loading from "components/Loading";
import SignIn from "components/sign/SignIn";
import SignUp from "components/sign/SignUp";
import FormHeader from "components/form/FormHeader";
import Customize from "components/customize/Customize";
import SignInOptions from "components/sign/SignInOptions";

// presentations
import HowToPlayPresentation from "components/presentations/HowToPlayPresentation";

// interfaces
import { UserSignExternalProps } from "interfaces/user";
import { AuthProps, AuthResourceProps } from "interfaces/auth";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

// services
import UserService from "services/UserService";
import StripeService from "services/StripeService";
import GoogleAuthService from "services/auth/GoogleAuthService";
import GithubAuthService from "services/auth/GithubAuthService";
import FirebaseAuthService from "services/auth/FirebaseAuthService";
import PlayerFirebaseService from "services/firebase/PlayerFirebaseService";

// Swiper
import { IonicSlides } from "@ionic/react";
import { Swiper as SwiperProps } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// firebase
import { UserCredential } from "firebase/auth";

// parsers
import authParser from "parsers/authParser";

// utils
import color from "styles/color";
import styled from "styled-components";
import authUtils from "utils/authUtils";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${color.dark.bg};
  position: relative;

  .title {
    all: unset;
    font-size: 1.5em;
    text-align: center;
    color: ${color.grey.stronger};
  }

  .game_mode_button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    text-align: center;

    p {
      font-size: 1.2em;
      line-height: 1.4;
      padding: 0 20px;
      color: ${color.grey.strong};

      span.strong {
        color: ${color.grey.middle};
      }
    }

    &.campaign_mode_button {
      display: flex;
      flex-direction: column;
    }

    &.fast_game_mode_button {
      display: flex;
      flex-direction: column;

      .mode_title {
        color: ${color.red};
      }
    }
  }

  .sign {
    .submit_button {
      margin-top: 15px;
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
    }

    .body {
      flex: 1;
      padding: 0 30px;

      .feedback {
        margin-top: 20px;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 100px;

      p {
        all: unset;
        color: ${color.grey.strong};
      }
    }
  }
`;

const SIGN_GAME_MODES = 0;
const SIGN_IN_OPTIONS = 1;
const SIGN_IN_SLIDE = 2;

interface SignModesProps {
  close(): void;
  isOpen: boolean;
  finished(): void;
}

const Sign = ({ isOpen, close, finished }: SignModesProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(s.auth());
  const paymentUrlRef = useRef("");
  const authRef = useRef<AuthProps>();
  const swiperRef = useRef<SwiperProps | undefined>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isToSignUp, setIsToSignUp] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const [disableAllSignInInputs, setDisableAllSignInInputs] = useState(true);
  const [disableAllSignUpInputs, setDisableAllSignUpInputs] = useState(true);

  useEffect(listenToAuth, [auth]);

  function listenToAuth() {
    if (!auth) return;
    authRef.current = auth;
  }

  function handleSwiper(swiper: SwiperProps | undefined) {
    if (!swiper) return;
    swiperRef.current = swiper;
  }

  async function signInAnonymously() {
    try {
      setLoading(true);

      const resource = new FirebaseAuthService();
      const { user }: UserCredential = await resource.signInAnonymously();

      await new PlayerFirebaseService().update(user.uid, mapPlayer());

      const accessToken = await user.getIdToken();
      authUtils.setAccessToken(accessToken);

      setShowHowToPlay(true);
      setTimeout(() => completeSignFlow(authParser.fake(user.uid)));
    } finally {
      setLoading(false);
    }
  }

  function mapPlayer(): PlayerFirebaseUpdateProps {
    return {
      checkpoint: increment(0),
    };
  }

  function onSigned(auth: AuthProps, registered: boolean) {
    setPaymentUrl();

    if (!registered) {
      setShowCustomize(true);
      setTimeout(() => completeSignFlow(auth));
      return;
    }

    completeSignFlow(auth);
    setTimeout(finished);
  }

  function completeSignFlow(auth: AuthProps) {
    dispatch(authActions.set({ ...auth, user: { ...auth.user } }));
  }

  function dismissModal() {
    setLoading(false);
    setIsToSignUp(false);
    setShowCustomize(false);
    setShowHowToPlay(false);
    setDisableAllSignInInputs(true);

    setTimeout(close);
  }

  function goToGameModes() {
    setDisableAllSignInInputs(true);
    setTimeout(() => slideTo(SIGN_GAME_MODES));
  }

  function goToSignInOptions() {
    setDisableAllSignInInputs(true);
    setTimeout(() => slideTo(SIGN_IN_OPTIONS));
  }

  function goToSignIn() {
    setDisableAllSignInInputs(false);
    setTimeout(() => slideTo(SIGN_IN_SLIDE));
  }

  function slideTo(index: number) {
    if (!swiperRef.current) return;
    swiperRef.current.slideTo(index, 300);
  }

  function openSignUp() {
    setDisableAllSignUpInputs(false);
    setIsToSignUp(true);
  }

  function onClosedSignUp() {
    setDisableAllSignUpInputs(true);
    setIsToSignUp(false);
  }

  function onFinishedCustomization() {
    setShowHowToPlay(true);
  }

  async function googleLogin() {
    try {
      setLoading(true);

      const service = new GoogleAuthService();
      await sign(await service.login());
    } catch (error: unknown) {
      setLoading(false);

      if (error instanceof Error) setError(error.message);
      else setError("Ops something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  async function githubLogin() {
    try {
      setLoading(true);

      const service = new GithubAuthService();
      await sign(await service.login());
    } catch (error: unknown) {
      setLoading(false);

      if (error instanceof Error) setError(error.message);
      else setError("Ops something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  async function sign(user: UserSignExternalProps) {
    const userService = new UserService();
    const firebaseAuth = new FirebaseAuthService();

    const response = await userService.signInExternal(user);
    const auth: AuthResourceProps = response.data;

    authUtils.setAccessToken(auth.accessToken);
    await firebaseAuth.signIn(auth.firebaseToken);

    onSigned(authParser.map(auth), !!response.data.user.jarvisName);
  }

  async function setPaymentUrl() {
    try {
      const service = new StripeService();
      const response = await service.createCheckoutSession();

      paymentUrlRef.current = response.data.url;
      dispatch(paymentActions.set({ url: paymentUrlRef.current }));
    } catch (error) {
      console.log("************************** error :>> ", error);
    }
  }

  return (
    <Modal isOpen={isOpen} onDidDismiss={dismissModal}>
      <Content>
        <Loading loading={loading} />

        <Swiper
          slidesPerView={1}
          onSwiper={handleSwiper}
          modules={[IonicSlides]}
          noSwipingClass="no-swipe"
          initialSlide={SIGN_GAME_MODES}
        >
          <SwiperSlide>
            <div className="slide no-swipe">
              <FormHeader brand close={dismissModal} />

              <div
                onClick={goToSignInOptions}
                className="game_mode_button campaign_mode_button ion-activatable"
              >
                <IonRippleEffect style={{ color: color.yellow }} />
                <h2 className="nerdfy_yellow blink">Campaign</h2>

                <p>
                  Make <span className="nerdfy_green">progress</span>, track
                  your <span className="nerdfy_blue">improvements</span> and{" "}
                  <span className="nerdfy_purple">strengthen</span> your{" "}
                  <span className="nerdfy_white">problem-solving skills</span>.{" "}
                  <span className="strong">Challenges</span> will be released on{" "}
                  <span className="nerdfy_orange">weekdays</span>.
                </p>
              </div>

              <div
                className="game_mode_button fast_game_mode_button ion-activatable"
                onClick={signInAnonymously}
              >
                <IonRippleEffect style={{ color: color.red }} />
                <h2 className="mode_title blink">Demo</h2>

                <p>
                  <span className="strong">Anonymous</span> login, fast, ideal
                  for those who just want to explore.
                </p>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide sign no-swipe">
              <SignInOptions
                error={error}
                signUp={openSignUp}
                goForward={goToSignIn}
                goBack={goToGameModes}
                githubLogin={githubLogin}
                googleLogin={googleLogin}
              />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="slide sign no-swipe">
              <SignIn
                onSignedIn={onSigned}
                goBack={goToSignInOptions}
                disabledInputs={disableAllSignInInputs}
              />
            </div>
          </SwiperSlide>
        </Swiper>

        <SignUp
          show={isToSignUp}
          onSignedUp={onSigned}
          onClosed={onClosedSignUp}
          githubLogin={githubLogin}
          googleLogin={googleLogin}
          disabledInputs={disableAllSignUpInputs}
        />

        <Customize
          auth={auth}
          show={showCustomize}
          finished={onFinishedCustomization}
        />

        {showHowToPlay && <HowToPlayPresentation close={finished} />}
      </Content>
    </Modal>
  );
};

export default Sign;
