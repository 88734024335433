// components
import Bubble, { BubbleProps } from "handlers/bubble/Bubble";

const BubbleWarning = ({
  hide,
  name,
  footer,
  content,
  subHeader,
  finishedTyping,
  onAnimationEnd,
  customClasseName,
}: BubbleProps) => {
  return (
    <Bubble
      show
      hide={hide}
      name={name}
      footer={footer}
      content={content}
      subHeader={subHeader}
      labelClassName="nerdfy_yellow"
      finishedTyping={finishedTyping}
      onAnimationEnd={onAnimationEnd}
      customClasseName={customClasseName}
      footerIcon={
        <span className="jarvis_alert jarvis_alert_middle nerdfy_yellow pixelify_font_family">
          !
        </span>
      }
    />
  );
};

export default BubbleWarning;
