// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import { PlayerProps } from "interfaces/player";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

// utils
import { AllToOptional } from "utils/transformTypeUtils";

export const initialState: PlayerProps = {
  // stats
  help: 0,
  missed: 0,
  killed: 0,
  streak: 0,
  maxStreak: 0,
  checkpoint: 0,

  last: 0,

  quiz: {
    easy: 0,
    hard: 0,
    medium: 0,
  },

  rating: {
    value: 0,
    message: "",
  },
};

const update = createAction(
  "@async/player/update",
  (payload: PlayerFirebaseUpdateProps) => ({
    payload,
  })
);

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<PlayerProps>) => {
      return action.payload;
    },
    update: (state, action: PayloadAction<AllToOptional<PlayerProps>>) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

export const playerActions = {
  async: {
    update,
  },
  ...playerSlice.actions,
};

export default playerSlice.reducer;
