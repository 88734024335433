// redux
import { appActions } from "redux/slices/appSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// interfaces
import {
  SectionsBriefProps,
  SectionBriefChallengeDoneProps,
} from "interfaces/sectionsBrief";

// utils
import cloneDeep from "lodash/cloneDeep";

const initialState: SectionsBriefProps = {
  len: 0,
  done: 0,
  progress: 0,
};

export const sectionsBriefSlice = createSlice({
  name: "sectionsBrief",
  initialState,
  reducers: {
    set: (_state, action: PayloadAction<SectionsBriefProps>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      return action.payload;
    },
    updateChallengesDone: (
      state,
      action: PayloadAction<SectionBriefChallengeDoneProps[]>
    ) => {
      return updateChallengesDone(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appActions.logout, () => initialState);
  },
});

function updateChallengesDone(
  sectionsBrief: SectionsBriefProps,
  challengesDone: SectionBriefChallengeDoneProps[]
): SectionsBriefProps {
  const reduced = challengesDone.reduce((acc, challenge) => {
    const sectionBrief = acc[challenge.sectionId];

    if (!sectionBrief)
      return {
        ...acc,
      };

    sectionBrief.done++;
    sectionBrief.progress = sectionBrief.done / sectionBrief.len;

    acc.done++;
    return acc;
  }, cloneDeep(sectionsBrief));

  reduced.progress = reduced.done / reduced.len;
  return reduced;
}

export const sectionsBriefActions = sectionsBriefSlice.actions;
export default sectionsBriefSlice.reducer;
