// interfaces
import { ChallengeTypeHandlerCommonProps } from "components/challenge/Challenge";

// components
import SudoHandler from "handlers/challengeButtons/sudo/SudoHandler";
import ChallengeBattle from "components/challenge/battle/ChallengeBattle";

interface ChallengeExerciseProps extends ChallengeTypeHandlerCommonProps {
  onFailTag(): void;
}

const ChallengeExercise = ({
  code,
  paused,
  onFailTag,
  challenge,
  wrapperRef,
  isTagValid,
  onSuccessTag,
  handlerWrapperRef,
  wrapperNotifiersRef,
  handlerNotifiersRef,
}: ChallengeExerciseProps) => {
  return (
    <>
      <ChallengeBattle
        code={code}
        paused={paused}
        challenge={challenge}
        onFailedTag={onFailTag}
        wrapperRef={wrapperRef}
        isTagValid={isTagValid}
        onSuccessTag={onSuccessTag}
        handlerWrapperRef={handlerWrapperRef}
        handlerNotifiersRef={handlerNotifiersRef}
        wrapperNotifiersRef={wrapperNotifiersRef}
      />

      <SudoHandler
        paused={paused}
        challenge={challenge}
        wrapper={wrapperRef.current}
      />
    </>
  );
};

export default ChallengeExercise;
