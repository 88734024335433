// components
import FormHeader from "components/form/FormHeader";

// utils
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
  }
`;

interface IntroductionProps {
  goForward(): void;
  proceedDisabled: boolean;
}

const Introduction = ({ goForward, proceedDisabled }: IntroductionProps) => {
  return (
    <Wrapper>
      <FormHeader brandMono proceed={goForward} disabled={proceedDisabled} />
      <div className="content"></div>
    </Wrapper>
  );
};

interface BlankProps {
  goBack(): void;
  goForward(): void;
  disabledBack: boolean;
  proceedDisabled: boolean;
}

export const Blank = ({
  goBack,
  goForward,
  disabledBack,
  proceedDisabled,
}: BlankProps) => {
  return (
    <Wrapper>
      <FormHeader
        back={goBack}
        proceed={goForward}
        disabled={proceedDisabled}
        disabledBack={disabledBack}
      />
      <div className="content"></div>
    </Wrapper>
  );
};

export default Introduction;
