// react
import React, { useEffect, useState, useRef } from "react";

// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// components
import AvatarBittle from "components/avatars/AvatarBittle";
import ButtonRippleEffect from "components/buttons/ButtonRippleEffect";

// utils
import c from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div<{ bgImage: string }>`
  ::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: url(${(props) => props.bgImage});
  }

  .avatar_button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 6000;
    width: 50px;
    height: 45px;
    top: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 3px;
  }
`;

interface AvatarButtonProps {
  onClick(): void;
  customClassName: string;
  avatar: React.ReactNode;
  onAnimationEnd(animateName: string): void;
  color?: string;
}

const AvatarButton = ({
  avatar,
  onClick,
  customClassName,
  onAnimationEnd,
  color = c.yellow,
}: AvatarButtonProps) => {
  return (
    <ButtonRippleEffect
      color={color}
      onClick={onClick}
      onAnimationEnd={onAnimationEnd}
      className={`${customClassName} avatar_button`}
    >
      {avatar}
    </ButtonRippleEffect>
  );
};

interface BittlesHandlerProps {
  show: boolean;
  onClick(): void;
  paused: boolean;
  success: boolean;
}

const BittlesHandler = ({
  show,
  paused,
  success,
  onClick,
}: BittlesHandlerProps) => {
  const player = useSelector(s.player());
  const avatarAlreadySet = useRef(false);
  const [avatar, setAvatar] = useState(0);
  const [showAvatar, setShowAvatar] = useState(false);
  const [animation, setAnimation] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleAvatar, [player.checkpoint]);
  useEffect(handleFirstTimeShowAvatar, [show]);
  useEffect(handleAnimation, [show, success, paused]);

  function handleAnimation() {
    if (!avatarAlreadySet.current) return;

    if (paused) {
      setAnimation("animate__backOutLeft");
      return;
    }

    show
      ? setAnimation("animate__backInUp")
      : success
      ? setTimeout(() => setAnimation("animate__flip"))
      : setAnimation("animate__hinge");
  }

  function handleAvatar() {
    if (avatarAlreadySet.current) return;

    avatarAlreadySet.current = true;
    setAvatar(player.checkpoint + 1);
  }

  function handleFirstTimeShowAvatar() {
    if (!show) return;
    setTimeout(() => setShowAvatar(show), 250);
  }

  function onAnimationEnd(animateName: string) {
    if (animateName === "flip")
      return setTimeout(() => setAnimation("animate__backOutRight"));
  }

  return (
    <>
      {avatar && (
        <Wrapper bgImage={`/assets/imgs/monsters/${avatar}.svg`}>
          {showAvatar && (
            <AvatarButton
              onClick={onClick}
              onAnimationEnd={onAnimationEnd}
              customClassName={`animate__animated ${animation}`}
              avatar={
                <div className="animate_y_infinite">
                  <AvatarBittle index={avatar} isMad={!success} />
                </div>
              }
            />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default BittlesHandler;
