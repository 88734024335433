// components
import ButtonDuolingo, {
  ButtonDuolingoType,
} from "components/buttons/ButtonDuolingo";

// svgs
import GithubSvg from "svg/GithubSvg";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled(ButtonDuolingo)`
  background-color: #fff;
  color: ${color.grey.strong};

  span {
    display: inline-block;
    margin-right: 20px;
  }
`;

interface ButtonDuolingoIconProps extends ButtonDuolingoType {
  label?: string;
  onlyIcon?: boolean;
}

const ButtonDuolingoGithub = ({
  width,
  onClick,
  onlyIcon,
  disabled,
  widthFull,
  type = "button",
  label = "Log in with Github",
}: ButtonDuolingoIconProps) => {
  return (
    <Wrapper
      type={type}
      height="40px"
      width={width}
      onClick={onClick}
      disabled={disabled}
      widthFull={widthFull}
      boxShadowColor={color.grey.middle}
    >
      {onlyIcon ? (
        <GithubSvg />
      ) : (
        <>
          <span>
            <GithubSvg />
          </span>{" "}
          {label}
        </>
      )}
    </Wrapper>
  );
};

export default ButtonDuolingoGithub;
