// react
import React from "react";

// ionic react
import {
  IonTitle,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonMenuButton,
} from "@ionic/react";

// components
import BrandLogo, { BrandLogoMono } from "components/BrandLogo";

// icons
import SettingsIcon from "icons/SettingsIcon";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled(IonHeader)`
  --border-width: 0 0 0 !important;
  --padding-end: 6px;

  .custom_toolbar {
    --background: ${color.dark.bg} !important;
  }

  .menu_button {
    margin: 0 10px 0 0;
  }
`;

interface HeaderProps {
  brandMono?: boolean;
  button?: JSX.Element;
}

const Header = ({ button, brandMono }: HeaderProps) => {
  return (
    <Wrapper mode="md" className="safe--area">
      <IonToolbar className="custom_toolbar">
        <IonTitle>{brandMono ? <BrandLogoMono /> : <BrandLogo />}</IonTitle>

        <IonButtons slot="end">
          {button}{" "}
          <IonMenuButton color="secondary" className="menu_button">
            <SettingsIcon color={color.blue} />
          </IonMenuButton>
        </IonButtons>
      </IonToolbar>
    </Wrapper>
  );
};

export default React.memo(Header);
