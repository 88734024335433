// components
import Footer from "components/Footer";
import LinkButton from "components/LinkButton";
import FormHeader from "components/form/FormHeader";

// buttons
import ButtonDuolingoGithub from "components/buttons/ButtonDuolingoGithub";
import ButtonDuolingoGoogle from "components/buttons/ButtonDuolingoGoogle";
import ButtonDuolingoSignIn from "components/buttons/ButtonDuolingoSignIn";

// utils
import color from "styles/color";
import styled from "styled-components";
import ErrorFeedback from "components/feedbacks/ErrorFeedback";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;

    button {
      margin-bottom: 20px;
    }
  }

  .no_account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${color.grey.light};
    border-top: 2px solid ${color.grey.dark};
    padding: 5px 0;
  }
`;

interface SignInOptionsProps {
  error: string;
  goBack: () => void;
  signUp: () => void;
  goForward: () => void;
  githubLogin: () => void;
  googleLogin: () => void;
}

const SignInOptions = ({
  error,
  signUp,
  goBack,
  goForward,
  githubLogin,
  googleLogin,
}: SignInOptionsProps) => {
  return (
    <Wrapper>
      <FormHeader close={() => {}} back={goBack} />

      <div className="content">
        <ButtonDuolingoGithub widthFull onClick={githubLogin} />
        <ButtonDuolingoGoogle widthFull onClick={googleLogin} />
        <ButtonDuolingoSignIn onClick={goForward} />
      </div>

      {error && <ErrorFeedback err={error} />}

      <div className="no_account">
        <p>Don't have an account?</p>

        <LinkButton onClick={signUp}>Create an account</LinkButton>
      </div>

      <Footer />
    </Wrapper>
  );
};

export default SignInOptions;
