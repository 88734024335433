// components
import ButtonDuolingo, {
  ButtonDuolingoTemplateProps,
} from "components/buttons/ButtonDuolingo";

// utils
import color from "styles/color";

const ButtonDuolingoBlue = ({
  height,
  onClick,
  children,
  disabled,
  className,
  widthFull,
  type = "button",
}: ButtonDuolingoTemplateProps) => {
  return (
    <ButtonDuolingo
      type={type}
      height={height}
      bg={color.blue}
      onClick={onClick}
      disabled={disabled}
      color={color.white}
      widthFull={widthFull}
      className={className}
      boxShadowColor="#256bb2"
    >
      {children}
    </ButtonDuolingo>
  );
};

export default ButtonDuolingoBlue;
