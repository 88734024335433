// drag
import interact from "interactjs";

export interface AlertFactoryProps {
  content: string;
  animation?: string;
}

export enum AlertFactoryType {
  Danger = 1,
  Success,
  Congrats,
  Light,
  Warning,
  Info,
  Energy,
  Pink,
}

export interface AlertFactoryProps {
  content: string;
  type: AlertFactoryType;
  slow?: boolean;
  slower?: boolean;
  animation?: string;
}

interface AlertAnimateBoundsProps {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

interface AlertAnimateProps {
  wrapper: HTMLDivElement;
  bounds: AlertAnimateBoundsProps;
  factories: (() => HTMLDivElement)[];
}

export const AlertFactory = ({
  type,
  slow,
  slower,
  content,
  animation = "animate__fadeIn",
}: AlertFactoryProps): (() => HTMLDivElement) => {
  return function () {
    const el = document.createElement("div");
    const typeClassName = getAlertNotifyType(type);

    el.className = `drag_alert_nerdfy no-swipe pixelify_font_family alert_nerdfy ${typeClassName} animate__animated ${animation} ${
      slower ? "animate__slower" : slow && "animate__slow"
    }`;
    el.innerHTML = content;

    return el;
  };
};

export const alertAnimate = ({
  bounds,
  wrapper,
  factories,
}: AlertAnimateProps) => {
  const factory = factories.shift();
  if (!factory) return;

  setTimeout(() => {
    const el = factory();
    el.addEventListener("animationend", onAnimationEnd);

    const { top, bottom, left, right } = bounds;

    if (top) el.style.top = top;
    if (left) el.style.left = left;
    if (right) el.style.right = right;
    if (bottom) el.style.bottom = bottom;

    el.setAttribute("data-x", `${left}px`);
    el.setAttribute("data-y", `${top}px`);

    wrapper.appendChild(el);

    function onAnimationEnd(e: AnimationEvent) {
      e.preventDefault();
      if (!el) return;

      el.classList.add("animate__fadeOut");

      if (e.animationName === "fadeOut") {
        el.removeEventListener("animationend", onAnimationEnd);
        el.classList.add("d_none");
        el.remove();

        setTimeout(() => alertAnimate({ wrapper, factories, bounds }));
      }
    }
  });
};

export function startAlertsDrag() {
  return interact(".drag_alert_nerdfy").draggable({
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: "parent",
      }),
    ],
    listeners: {
      move(event) {
        const { target } = event;
        const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
        const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

        target.style.top = `${y}px`;
        target.style.left = `${x}px`;

        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);
      },
    },
  });
}

export function alertLeft(
  wrapper: HTMLDivElement,
  factories: (() => HTMLDivElement)[],
  topMinus = 100
) {
  alertAnimate({
    wrapper,
    factories,
    bounds: {
      left: `10px`,
      top: `${wrapper.clientHeight - topMinus}px`,
    },
  });
}

// encyclopedia alerts

export const AlertVictory = (content: string): (() => HTMLDivElement) => {
  return AlertFactory({
    content,
    slow: true,
    type: AlertFactoryType.Success,
  });
};

export const AlertPink = (content: string): (() => HTMLDivElement) => {
  return AlertFactory({
    content,
    slow: true,
    type: AlertFactoryType.Pink,
  });
};

// private

function getAlertNotifyType(type: AlertFactoryType): string {
  return type === AlertFactoryType.Danger
    ? "nerdfy_red"
    : type === AlertFactoryType.Success
    ? "nerdfy_green"
    : type === AlertFactoryType.Congrats
    ? "nerdfy_purple"
    : type === AlertFactoryType.Light
    ? "nerdfy_white"
    : type === AlertFactoryType.Info
    ? "nerdfy_blue"
    : type === AlertFactoryType.Warning
    ? "nerdfy_yellow"
    : type === AlertFactoryType.Energy
    ? "nerdfy_acai"
    : type === AlertFactoryType.Pink
    ? "nerdfy_pink"
    : "nerdfy_green";
}
