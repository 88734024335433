// redux
import { useSelector } from "react-redux";
import { selector as s } from "redux/selectors";

// enums
import { BubbleType } from "enums/bubbleEnum";

// components
import BubbleInfo from "handlers/bubble/BubbleInfo";
import BubbleSudo from "handlers/bubble/BubbleSudo";
import BubbleAlert from "handlers/bubble/BubbleAlert";
import BubbleRavian from "handlers/bubble/BubbleRavian";
import BubbleWarning from "handlers/bubble/BubbleWarning";

interface BubbleHandlerProps {
  hide(): void;
  show: boolean;
  content: string;
  type: BubbleType;
  finishedTyping?(): void;
  footer?: React.ReactNode;
  customClasseName?: string;
  subHeader?: React.ReactNode;
  onAnimationEnd?(e: React.AnimationEvent<HTMLDivElement>): void;
}

const BubbleHandler = ({
  hide,
  show,
  type,
  content,
  footer,
  subHeader,
  finishedTyping,
  onAnimationEnd,
  customClasseName,
}: BubbleHandlerProps) => {
  const npc = useSelector(s.npc());

  return (
    <>
      {show && type === BubbleType.Market && (
        <BubbleRavian
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name="Vendedor Raviano"
          finishedTyping={finishedTyping}
          onAnimationEnd={onAnimationEnd}
          customClasseName={customClasseName}
        />
      )}

      {show && type === BubbleType.Sudo && (
        <BubbleSudo
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
          customClasseName={customClasseName}
        />
      )}

      {show && type === BubbleType.Info && (
        <BubbleInfo
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          finishedTyping={finishedTyping}
          onAnimationEnd={onAnimationEnd}
          customClasseName={customClasseName}
        />
      )}

      {show && type === BubbleType.Warning && (
        <BubbleWarning
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          finishedTyping={finishedTyping}
          onAnimationEnd={onAnimationEnd}
          customClasseName={customClasseName}
        />
      )}

      {show && type === BubbleType.Alert && (
        <BubbleAlert
          hide={hide}
          footer={footer}
          content={content}
          subHeader={subHeader}
          name={npc.jarvis.name}
          onAnimationEnd={onAnimationEnd}
          finishedTyping={finishedTyping}
          customClasseName={customClasseName}
        />
      )}
    </>
  );
};

export default BubbleHandler;
