// services
import FirebaseCoreResourceService from "services/core/FirebaseCoreResourceService";

// interfaces
import { PlayerFirebaseProps } from "interfaces/playerFirebase";
import { PlayerFirebaseUpdateProps } from "interfaces/playerFirebase";

class PlayerFirebaseService {
  private _resource = "players";
  private _firebase: FirebaseCoreResourceService;

  constructor() {
    this._firebase = new FirebaseCoreResourceService();
  }

  async get(userId: number | string): Promise<PlayerFirebaseProps> {
    const path = `${this._resource}/${userId}`;
    const response = await this._firebase.get(path);

    return response.data() as PlayerFirebaseProps;
  }

  async update(
    userId: number | string,
    values: PlayerFirebaseUpdateProps
  ): Promise<void> {
    if (!userId) return Promise.reject();
    return this._firebase.set(`${this._resource}/${userId}`, values);
  }
}

export default PlayerFirebaseService;
