// redux
import { selector as s } from "redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { challengeFlowActions } from "redux/slices/challengeFlowSlice";

// components
import ButtonPlayPauseToggle from "components/buttons/ButtonPlayPauseToggle";

// utils
import color from "styles/color";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100px;

  > ul {
    all: unset;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    background: ${color.dark.bg};
    color: ${color.grey.strong};
  }
`;

interface ChallengeOptionsFooterProps {
  wrapper: HTMLDivElement | null;
  disabled?: boolean;
}

const ChallengeOptionsFooter = ({
  wrapper,
  disabled,
}: ChallengeOptionsFooterProps) => {
  const dispatch = useDispatch();
  const { paused } = useSelector(s.challengeFlow());

  function play() {
    dispatch(challengeFlowActions.unpause());
  }

  function pause() {
    dispatch(challengeFlowActions.pause());
  }

  return (
    <Wrapper>
      <ul>
        <li>
          <ButtonPlayPauseToggle
            play={play}
            pause={pause}
            paused={paused}
            disabled={disabled}
          />
        </li>
      </ul>
    </Wrapper>
  );
};

export default ChallengeOptionsFooter;
