// interfaces
import { AttentionWhoreElProps } from "components/attentionWhores/AttentionWhore";

// icons
import CodeTagSvg from "svg/CodeTagSvg";

// utils
import color from "styles/color";

const IconWhoreLabs = ({ active }: AttentionWhoreElProps) => {
  return (
    <CodeTagSvg
      width="30"
      height="30"
      color={active ? color.pink : undefined}
    />
  );
};

export default IconWhoreLabs;
