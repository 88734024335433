// components
import PlayerFirebaseSync from "firebaseSync/PlayerFirebaseSync";
import ChallengesFirebaseSync from "firebaseSync/ChallengesFirebaseSync";
import JarvisChatsFirebaseSync from "firebaseSync/JarvisChatsFirebaseSync";
import ChallengeQuizzesFirebaseSync from "firebaseSync/ChallengeQuizzesFirebaseSync";

// interfaces
import { UserProps } from "interfaces/user";

// firestore
import { DocumentSnapshot } from "firebase/firestore";

export type hasPendingWritesDocType = DocumentSnapshot | undefined;

interface FirebaseSyncProps {
  user: UserProps;
  languageId: number;
  classRoomId: number;
}

const FirebaseSync = ({ user, languageId, classRoomId }: FirebaseSyncProps) => {
  /*
   O firebase atualiza 2x, uma internamente (hasPendingWrites: true) 
   e outra externamente (hasPendingWrites: false)

   Apenas para garantir que apenas uma chamada (internamente) seja feita
  */
  function isCachedData(doc: hasPendingWritesDocType): boolean {
    if (!doc) return false;
    if (!doc.metadata) return false;

    return doc.metadata.hasPendingWrites;
  }

  return (
    <>
      <PlayerFirebaseSync user={user} />

      <ChallengesFirebaseSync
        userId={user.id}
        classRoomId={classRoomId}
        isCachedData={isCachedData}
      />

      <ChallengeQuizzesFirebaseSync
        userId={user.id}
        classRoomId={classRoomId}
        isCachedData={isCachedData}
      />

      <JarvisChatsFirebaseSync
        userId={user.id}
        classRoomId={classRoomId}
        isCachedData={isCachedData}
      />
    </>
  );
};

export default FirebaseSync;
