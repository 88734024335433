// interfaces
export default class GithubNotEmailFoundError extends Error {
  static errorName = "GithubNotEmailFoundError";

  constructor(
    message: string = "Email not available (Github). Add email as public and try again."
  ) {
    super(message);
    this.name = "GithubNotEmailFoundError";
  }
}
