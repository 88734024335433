// react
import { useRef } from "react";

// typewriter-effect
import TW, { TypewriterClass } from "typewriter-effect";

// constants
import APP_NERDFY from "constants/app";

interface TypewriterProps {
  content: string;
  delay?: number;
  started?(): void;
  finished?(): void;
  typingDelay?: number;
}

const Typewriter = ({
  delay,
  started,
  content,
  finished,
  typingDelay,
}: TypewriterProps) => {
  const INIT_DELAY = delay || 1000;
  const TYPEWRITING_DELAY = typingDelay || APP_NERDFY.typingDelay;
  const typewriter = useRef<TypewriterClass>();

  function init(t: TypewriterClass) {
    if (!t) return;
    if (typewriter.current) return;

    typewriter.current = t;
    start(typewriter.current, content, INIT_DELAY);
  }

  function start(typewriter: TypewriterClass, content: string, delay: number) {
    setTimeout(() => {
      started && started();
      setTimeout(() =>
        typewriter.typeString(content).start().callFunction(onFinished)
      );
    }, delay);
  }

  function onFinished() {
    if (finished) finished();
  }

  return (
    <TW onInit={init} options={{ cursor: "", delay: TYPEWRITING_DELAY }} />
  );
};

export default Typewriter;
