// svgs
import JarvisSvg from "svg/JarvisSvg";

interface AvatarJarvisProps {
  width?: string;
  height?: string;
}

const AvatarJarvis = ({ width = "35", height = "35" }: AvatarJarvisProps) => {
  return <JarvisSvg width={width} height={height} />;
};

export default AvatarJarvis;
